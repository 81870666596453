import React from 'react';
import Image from 'lib/image-container';
import cx from 'classnames';

export type ValuesCarouselCardProps = {
    title: React.ReactNode;
    description: React.ReactNode;
    imagePreviewUrl?: string;
    isSpacious?: boolean;
    onClickClose?: (event?: React.MouseEvent) => void;
};

function ValuesCarouselCard(props: ValuesCarouselCardProps) {
    const className = cx({
        'values-carousel__card': true,
        'values-carousel__card--spacious': props.isSpacious,
    });

    return (
        <div className={className}>
            <div className="values-carousel__symbol">
                {props.imagePreviewUrl ? (
                    <Image src={props.imagePreviewUrl} alt="" fill={true} />
                ) : null}
            </div>

            <div className="values-carousel__card-title">{props.title}</div>

            <div className="values-carousel__card-description">{props.description}</div>

            {/* <button className="values-carousel__card-close" onClick={props.onClickClose}>
                <GenericClose />
            </button> */}
        </div>
    );
}

export default ValuesCarouselCard;
