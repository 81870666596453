/**
 * This polyfill adresses the issue on ios 13/14 devices, which occur on orientation change event.
 */
export default function initPolyfllForVirtualUnits() {
    if (
        // @ts-ignore
        global.window.viewportUnitsBuggyfill &&
        (global.document.documentElement.classList.contains('android') ||
            global.document.documentElement.classList.contains('ios'))
    ) {
        // @ts-ignore
        global.window.viewportUnitsBuggyfill.init({ force: true });
    }
}
