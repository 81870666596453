import React from 'react';
import { NextSeo } from 'next-seo';
import lexemes from 'components/lexemes/sharing-assets/lexemes';

const PAGE_PARTNER_NAME = 'Bumble';

const setAbsPathForImageUrl = (imageUrl: string) =>
    global.window
        ? `${global.window.location.origin}${imageUrl}`
        : `https://team.bumble.com${imageUrl}`;

const SEO = {
    get TAB_TITLE() {
        return lexemes.lxBumbleHomepageTabTitle({ page_partner_name: PAGE_PARTNER_NAME });
    },
    get NAME() {
        return lexemes.lxBumbleHomepageTabTitle({ page_partner_name: PAGE_PARTNER_NAME });
    },
    get TITLE() {
        return lexemes.lxBumbleHomepageTabTitle({ page_partner_name: PAGE_PARTNER_NAME });
    },
    get DESCRIPTION() {
        return lexemes.lxBumbleHiringBlockText;
    },
    URL: 'https://team.bumble.com',
    IMAGE_URL: setAbsPathForImageUrl('/social-sharing.jpg'),
    get IMAGE_ALT() {
        return lexemes.lxBumbleHomepageTabTitle({ page_partner_name: PAGE_PARTNER_NAME });
    },
};

export type PageSEOPropsType = {
    browserTab?: string;
    // seoTitle?: string;
    seoDescription?: string;
    seoUrl?: string;
    openTitle?: string;
    openDescription?: string;
    openImageUrl?: string;
    openImageAlt?: string;
    openSiteName?: string;
};

const PageSEO = (props: PageSEOPropsType) => {
    const canonicalUrl = props.seoUrl ? props.seoUrl : SEO.URL;
    const imageUrl = props.openImageUrl ? setAbsPathForImageUrl(props.openImageUrl) : SEO.IMAGE_URL;
    const imageAlt = props.openImageAlt ? props.openImageAlt : SEO.IMAGE_ALT;
    const pageTitle = props.browserTab ? props.browserTab : SEO.TAB_TITLE;
    const pageDescriotion = props.seoDescription ? props.seoDescription : SEO.DESCRIPTION;
    const siteName = props.openSiteName ? props.openSiteName : SEO.NAME;

    return (
        <NextSeo
            title={pageTitle}
            description={pageDescriotion}
            canonical={canonicalUrl}
            openGraph={{
                url: canonicalUrl,
                title: props.openTitle ? props.openTitle : pageTitle,
                description: props.openDescription ? props.openDescription : pageDescriotion,
                images: [
                    {
                        url: imageUrl,
                        alt: imageAlt,
                        width: 1200,
                        height: 630,
                    },
                ],
                site_name: siteName,
            }}
            twitter={{
                handle: '@handle',
                site: '@site',
                cardType: 'summary_large_image',
            }}
        />
    );
};

export default PageSEO;
