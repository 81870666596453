import React from 'react';
import { GetServerSidePropsContext } from 'next';
import { ContentBlock, Maybe } from 'interfaces/team-bumble-microsite-cms';
import { getPostByUrl, getIndexPage } from 'lib/api';
import { getLocale } from 'lib/localization';
import { SupportedLanguage } from 'lib/lexemes/supported-languages';
import PageTemplate from 'components/page-template/page-template';
import lexemes from '../../components/lexemes/benefits/lexemes';
import PostContent from 'components/Blocks/PostContent/PostContent';
import { supportedLanguages } from 'lib/supported-languages';
import { confirmUrl } from 'lib/confirm-url';
import { getGeoCountry } from 'lib/get-geo-country';
import { handleGeoMock } from 'lib/handle-geo-mock';

const urlToString = (url: string) => url.toString().replace(',', '/');

function setItemsToString(list: string[]) {
    const items = list.map((item) => urlToString(item));

    return items.toString();
}

function makeUrlFromQueryParam(param: string | string[]) {
    if (!param) {
        return '';
    }

    return typeof param === 'string' ? urlToString(param) : setItemsToString(param);
}

async function setupLangAndUrlParams(
    query: GetServerSidePropsContext<{ lang: SupportedLanguage }>['query'],
    locale?: SupportedLanguage
) {
    let redirectToMainPageWithLocalisation = false;
    let lang: SupportedLanguage = 'en';
    // add tests to cover
    // @ts-ignore
    let url = `/${makeUrlFromQueryParam(query.lang)}/${makeUrlFromQueryParam(query.slug)}`;
    /**
     * case for index page with undefined language
     */
    if (Object.values(query).length === 0 || (!query.lang && !query.slug)) {
        redirectToMainPageWithLocalisation = true;
    } else if (
        typeof query.lang === 'string' &&
        supportedLanguages.includes(query.lang as SupportedLanguage)
    ) {
        lang = getLocale(undefined, query);

        url = `/${query.slug?.toString().replace(',', '/')}`;
    } else if (locale) {
        lang = locale;

        // @ts-ignore
        url = `/${makeUrlFromQueryParam(query.lang)}/${makeUrlFromQueryParam(query.slug)}`;
    } else if (!query.lang) {
        const slug = (
            query.slug === 'string' ? query.slug : query.slug && query.slug[0]
        ) as SupportedLanguage;

        if (supportedLanguages.includes(slug)) {
            lang = slug;

            url = `/${query.slug?.toString().replace(',', '/')}`;
            redirectToMainPageWithLocalisation = true;
        }
    }

    /**
     * corner case, when lang/locale is not defined
     */
    url = url.replace('//', '/');

    return {
        lang,
        url,
        redirectToMainPageWithLocalisation,
    };
}

export type PageProps = {
    lang: SupportedLanguage;
    title: string;
    blocks: ContentBlock[];
    description?: string;
    showFooter: boolean;
    footerTitleText?: Maybe<string> | undefined;
    showCta?: boolean;
    ctaButtonText?: Maybe<string> | undefined;
    ctaButtonUrl?: Maybe<string> | undefined;
    ctaButtonTargetNewWindow?: boolean;
};

export default function IndexPage(props: PageProps) {
    const {
        title,
        description,
        blocks,
        showFooter,
        footerTitleText,
        showCta,
        ctaButtonText,
        ctaButtonUrl,
        ctaButtonTargetNewWindow,
    } = props;

    return (
        <PageTemplate
            browserTab={title || lexemes.lxSeoPageTitle}
            seoDescription={description || lexemes.lxSeoPageDescription}
            hasFooterCta={showFooter}
            footerTitleText={footerTitleText}
            hasFooterCtaButton={showCta}
            ctaButtonText={ctaButtonText}
            ctaButtonUrl={ctaButtonUrl}
            ctaButtonTargetNewWindow={ctaButtonTargetNewWindow}
            dynamicFooter={true}
            // @TODO: add other seo data later: title, text, images
        >
            {blocks ? <PostContent blocks={blocks} /> : null}
        </PageTemplate>
    );
}

async function getPageProps(url: string, lang: SupportedLanguage) {
    const {
        title,
        description,
        blocks,
        showFooter,
        footerTitleText,
        showCta,
        ctaButtonText,
        ctaButtonUrl,
        ctaButtonTargetNewWindow,
    } = await getPostByUrl(url, lang);

    // this is the case, when we have unknown data from BE
    if (!showFooter) {
        return {
            notFound: true,
        };
    }

    return {
        lang,
        title,
        description,
        blocks,
        showFooter,
        footerTitleText,
        showCta,
        ctaButtonText,
        ctaButtonUrl,
        ctaButtonTargetNewWindow,
    };
}

async function getIndexPageProps(lang: SupportedLanguage) {
    const {
        title,
        description,
        blocks,
        showFooter,
        footerTitleText,
        showCta,
        ctaButtonText,
        ctaButtonUrl,
        ctaButtonTargetNewWindow,
    } = await getIndexPage(lang);

    // this is the case, when we have unknown data from BE
    if (!showFooter) {
        return {
            notFound: true,
        };
    }

    return {
        lang,
        title,
        description,
        blocks,
        showFooter,
        footerTitleText,
        showCta,
        ctaButtonText,
        ctaButtonUrl,
        ctaButtonTargetNewWindow,
    };
}

type GetServerSidePropsType = GetServerSidePropsContext<{ lang: SupportedLanguage }> & {
    locale?: SupportedLanguage;
};

export const getServerSideProps = async function ({
    query,
    locale,
    res,
    req,
}: GetServerSidePropsType) {
    /**
     * Temporary fix
     * trying to avoid too many unnessary requests
     */

    res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');

    let country = getGeoCountry(req);
    const countryMock = handleGeoMock(query, req, res);

    if (countryMock) {
        country = countryMock;
    }

    /**
     * we only use lang, slug from query
     * & lang in getLocale(undefined, query)
     */
    const { lang, url, redirectToMainPageWithLocalisation } = await setupLangAndUrlParams(
        query,
        locale
    );
    const unknownUrl = !confirmUrl(url);

    if (unknownUrl) {
        return {
            notFound: true,
        };
    }

    const pageProps = redirectToMainPageWithLocalisation
        ? await getIndexPageProps(lang)
        : await getPageProps(url, lang);

    return {
        props: {
            geoIpCountry: country,
            ...pageProps,
        },
    };
};
