import React from 'react';
import { BlockProps } from '../index.types';
import SuccessStories, {
    PageHeroBlockPropsType,
} from 'components/page-hero-block/page-hero-block-dynamic';
import { decodePossible } from 'lib/decode';

const INIT_ITEM = {
    imageSrc: '',
    title: '',
    text: '',
};

function setSuccessStoryList(data: BlockProps['block']['innerBlocks']) {
    const list: PageHeroBlockPropsType['stories'] = [];

    data?.forEach((innerBlock) => {
        const listItem = Object.create(INIT_ITEM);

        const imageSrc = innerBlock?.attributes?.filter(
            (attribute) => attribute?.name === 'imageSrc'
        )[0]?.value;

        if (imageSrc) {
            listItem.imageSrc = imageSrc;
        }

        const title = innerBlock?.attributes?.filter((attribute) => attribute?.name === 'title')[0]
            ?.value;

        if (title) {
            listItem.title = decodePossible(title);
        }

        const text = innerBlock?.attributes?.filter((attribute) => attribute?.name === 'text')[0]
            ?.value;

        if (text) {
            listItem.text = decodePossible(text);
        }

        list.push(listItem);
    });

    return list;
}

type SuccessStoriesType = BlockProps & PageHeroBlockPropsType;

export default function SuccessStoriesBlock({ block, ...props }: SuccessStoriesType) {
    const stories = setSuccessStoryList(block.innerBlocks);

    return <SuccessStories title={decodePossible(props.title)} stories={stories} />;
}
