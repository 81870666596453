import React from 'react';
import cx from 'classnames';

type Props = {
    logo?: React.ReactNode;
    isActive?: boolean;
    closeAction?: React.ReactNode;
    menuList?: React.ReactNode[];
    downloadAction?: React.ReactNode;
    jsClass?: string;
    // jsBackDropClass?: string;
    closePanel?: (...args: any[]) => any;
};

/**
 * Make screen width < 1024 px in order to see the component
 */
function MobilePanel(props: Props) {
    const {
        logo,
        isActive,
        closeAction,
        menuList,
        downloadAction,
        closePanel,
        jsClass = 'js-mobile-navigation',
    } = props;

    const className = {
        block: cx({
            'mobile-panel': true,
            'is-active': isActive,
            [jsClass]: jsClass,
        }),
        backdrop: cx({
            'mobile-panel__backdrop': true,
        }),
    };

    return (
        <div className={className.block}>
            <div className="mobile-panel__inner">
                <div className="mobile-panel__header">
                    {logo ? <div className="mobile-panel__logo">{logo}</div> : null}
                    <div className="mobile-panel__close">{closeAction}</div>
                </div>

                {menuList ? (
                    <div className="mobile-panel__content">
                        {menuList.map((menu, index) => (
                            <div
                                className="mobile-panel__content-menu"
                                key={`mobile-panel-content-menu-${index}`}
                            >
                                {menu}
                            </div>
                        ))}
                    </div>
                ) : null}

                <div className="mobile-panel__download-app">{downloadAction}</div>
            </div>

            <div className={className.backdrop} onClick={closePanel}></div>
        </div>
    );
}

export default MobilePanel;
