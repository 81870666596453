import React from 'react';
import cx from 'classnames';

type HeroBlockProps = {
    title?: React.ReactNode;
    children?: React.ReactNode;
    frontContent?: React.ReactNode;
    isFullScreen?: boolean;
    isFluid?: boolean;
    color?: 'primary' | 'white';
    extraClass?: string;
    hasInnerContainer?: boolean;
    isStickyBlock?: boolean;
    isStickyContent?: boolean;
    hasStickyOnMobile?: boolean;
};

function HeroBlock(props: HeroBlockProps) {
    const className = {
        block: cx({
            'hero-block': true,
            'hero-block--fullscreen': props.isFullScreen,
            'hero-block--fluid': props.isFluid,
            'hero-block--vertical': props.title,
            [`hero-block--${props.color}`]: props.color,
            [props.extraClass as string]: props.extraClass,
            'is-sticky': props.isStickyBlock,
            'has-mobile-sticky': props.hasStickyOnMobile,
        }),
        inner: cx({
            'hero-block__inner': true,
            'is-sticky': props.isStickyContent,
            'has-mobile-sticky': props.hasStickyOnMobile,
        }),
    };
    const HeroBlockElement = (
        <div className={className.block}>
            {props.title ? <div className="hero-block__title">{props.title}</div> : null}{' '}
            {props.hasInnerContainer ? (
                <div className={className.inner}>{props.children}</div>
            ) : (
                props.children
            )}
            {props.frontContent ? (
                <div className="hero-block__front-content">{props.frontContent}</div>
            ) : null}
        </div>
    );

    return HeroBlockElement;
}

export default HeroBlock;
