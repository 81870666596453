import React from 'react';
import { BlockProps } from '../index.types';
import PageValuesSection, {
    PageValuesListProps,
} from 'components/page-values-block/page-values-block-dynamic';
import { decodePossible } from 'lib/decode';

const INIT_VIDEO_ITEM = {
    text: '',
    title: '',
    imageSrc: '',
    imagePreviewUrl: '',
};

function setValuesList(data: BlockProps['block']['innerBlocks']) {
    const list: PageValuesListProps['values'] = [];

    data?.forEach((innerBlock) => {
        const listItem = Object.create(INIT_VIDEO_ITEM);

        const text = innerBlock?.attributes?.filter((attribute) => attribute?.name === 'text')[0]
            ?.value;

        if (text) {
            listItem.text = text;
        }

        const title = innerBlock?.attributes?.filter((attribute) => attribute?.name === 'title')[0]
            ?.value;

        if (title) {
            listItem.title = title;
        }

        const imageSrc = innerBlock?.attributes?.filter(
            (attribute) => attribute?.name === 'imageSrc'
        )[0]?.value;

        if (imageSrc) {
            listItem.imageSrc = imageSrc;
        }

        const imagePreviewUrl = innerBlock?.attributes?.filter(
            (attribute) => attribute?.name === 'imagePreviewSrc'
        )[0]?.value;

        if (imagePreviewUrl) {
            listItem.imagePreviewUrl = imagePreviewUrl;
        }

        list.push(listItem);
    });

    return list;
}

type PageValuesSectionType = BlockProps & PageValuesListProps;

export default function PageValuesSectionBlock({ block, ...props }: PageValuesSectionType) {
    const values = setValuesList(block.innerBlocks);

    return <PageValuesSection title={decodePossible(props.title)} values={values} />;
}
