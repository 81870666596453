import React from 'react';
import Header from 'components/header/header';
import Icon from 'components/icon/icon';
import Logo from 'components/logo/logo';
import Button from 'components/button/button';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import lexemes from 'components/lexemes/index/lexemes';
import { Lexeme } from '@bumble/localization/components';
import { routes } from 'lib/constants-team-bumble';

const mobileMenuItemsTeamBumble = [
    {
        title: <Lexeme path="bumblesite@team_bumble.page_index.navigation.links.about" />,
        url: routes.about,
    },
    {
        title: <Lexeme path="bumblesite@team_bumble.page_index.navigation.links.life" />,
        url: routes.life,
    },
    {
        title: <Lexeme path="bumblesite@team_bumble.page_index.navigation.links.teams" />,
        url: routes.teams,
    },
    {
        title: <Lexeme path="bumblesite@team_bumble.page_index.navigation.links.benefits" />,
        url: routes.benefits,
    },
];

const PageHeader = () => {
    const isBreakpoint = useMediaQuery(MediaQueryWidth['desktop-md']);
    const onOpenMenu = React.useCallback(() => {
        global.document.body.classList.toggle('is-opened-mobile-nav');
        // This is super crappy but need to leave it as is for now
        const selector = global.document.querySelector('.js-mobile-navigation');

        if (selector) {
            selector.classList.toggle('is-active');
        }
    }, []);

    const desktopMenuItems = mobileMenuItemsTeamBumble.map((menuItem, index) => (
        <Button
            // labelText={menuItem.title}
            text={menuItem.title}
            href={menuItem.url}
            key={index}
            extraClass="button--dw-action"
        />
    ));

    return (
        <Header
            hasShadow={true}
            extraClass={isBreakpoint ? 'has-shadow' : 'has-actions-v-aligned'}
            logo={<Logo />}
            actions={
                isBreakpoint
                    ? [
                          <Button
                              labelText="Open Menu"
                              icon={<Icon name={Icon.Name.Menu} size="md" />}
                              onClick={onOpenMenu}
                              key="mobile-menu"
                              testId="mobile-menu"
                              isCircle={true}
                          />,
                      ]
                    : [
                          //   <Button
                          //       labelText="Open Menu"
                          //       text={lexemes.lxNavigationMenu}
                          //       onClick={onOpenMenu}
                          //       key="open-menu"
                          //       extraClass="button--dw-action"
                          //   />,
                          ...desktopMenuItems,
                          <Button
                              labelText="Open roles"
                              text={lexemes.lxNavigationOpenRoles}
                              href={routes.open_roles}
                              key="Open roles"
                              extraClass="button--dw-action"
                          />,
                      ]
            }
        />
    );
};

export default PageHeader;
