import Localization from 'lib/localization';

export default {
    // @TODO update after PRD update - stage 2
    get lxSeoPageTitle() {
        return 'Bumble - Life';
    },

    get lxSeoPageDescription() {
        return 'Do you want your work to have a real human impact and change the course of people’s lives for the better? Join our team!';
    },

    get lxLifeBlockHeader() {
        return Localization.get('bumblesite@team_bumble.page_life.life_block.header');
    },
    get lxLifeBlockTitle() {
        return Localization.get('bumblesite@team_bumble.page_life.life_block.title');
    },
    lxLifeBlockTextFirst(params: { page_partner_name: string }) {
        return Localization.get('bumblesite@team_bumble.page_life.life_block.text.first', params);
    },
    lxLifeBlockTextSecond() {
        return Localization.get('bumblesite@team_bumble.page_life.life_block.text.second');
    },
    get lxMovesBlockHeader() {
        return Localization.get('bumblesite@team_bumble.page_life.moves_block.header');
    },
    get lxMovesBlockTitle() {
        return Localization.get('bumblesite@team_bumble.page_life.moves_block.title');
    },

    get lxMovesForGoodBlockTitle() {
        return Localization.get('bumblesite@team_bumble.page_life.moves_block.good.title');
    },
    get lxMovesForGoodBlockText() {
        return Localization.get('bumblesite@team_bumble.page_life.moves_block.good.text');
    },
    get lxMovesForChangeBlockTitle() {
        return Localization.get('bumblesite@team_bumble.page_life.moves_block.change.title');
    },
    get lxMovesForChangeBlockText() {
        return Localization.get('bumblesite@team_bumble.page_life.moves_block.change.text');
    },
    get lxMovesForCareerBlockTitle() {
        return Localization.get('bumblesite@team_bumble.page_life.moves_block.career.title');
    },
    get lxMovesForCareerBlockText() {
        return Localization.get('bumblesite@team_bumble.page_life.moves_block.career.text');
    },

    lxTestimonialsBlockHeader(params: { page_partner_name: string }) {
        return Localization.get(
            'bumblesite@team_bumble.page_life.testimonials_block.header',
            params
        );
    },
    get lxTestimonialsBlockTitle() {
        return Localization.get('bumblesite@team_bumble.page_life.testimonials_block.title');
    },
    get lxTestimonialsBlockEmployeeFirstCaption() {
        return Localization.get(
            'bumblesite@team_bumble.page_life.testimonials_block.employee_first.caption'
        );
    },
    get lxTestimonialsBlockEmployeeFirstLocation() {
        return Localization.get(
            'bumblesite@team_bumble.page_life.testimonials_block.employee_first.location'
        );
    },
    lxTestimonialsBlockEmployeeSecondCaption(params: { page_partner_name: string }) {
        return Localization.get(
            'bumblesite@team_bumble.page_life.testimonials_block.employee_second.caption',
            params
        );
    },
    get lxTestimonialsBlockEmployeeSecondLocation() {
        return Localization.get(
            'bumblesite@team_bumble.page_life.testimonials_block.employee_second.location'
        );
    },
    lxTestimonialsBlockEmployeeThirdCaption(params: { page_partner_name: string }) {
        return Localization.get(
            'bumblesite@team_bumble.page_life.testimonials_block.employee_third.caption',
            params
        );
    },
    lxTestimonialsBlockEmployeeThirdLocation(params: { page_partner_name: string }) {
        return Localization.get(
            'bumblesite@team_bumble.page_life.testimonials_block.employee_third.location',
            params
        );
    },
    lxTestimonialsBadooBlockTitle(params: { page_partner_name: string }) {
        return Localization.get(
            'bumblesite@team_bumble.page_life.testimonials_badoo_block.title',
            params
        );
    },

    get lxValuesBlockHeader() {
        return Localization.get('bumblesite@team_bumble.page_life.values_block.header');
    },
    get lxValuesBlockGrowthTitle() {
        return Localization.get('bumblesite@team_bumble.page_life.values_block.growth.title');
    },
    get lxValuesBlockGrowthText() {
        return Localization.get('bumblesite@team_bumble.page_life.values_block.growth.text');
    },
    get lxValuesBlockFirstMoveTitle() {
        return Localization.get('bumblesite@team_bumble.page_life.values_block.first_move.title');
    },
    get lxValuesBlockFirstMoveText() {
        return Localization.get('bumblesite@team_bumble.page_life.values_block.first_move.text');
    },
    get lxValuesBlockHonestyTitle() {
        return Localization.get('bumblesite@team_bumble.page_life.values_block.honesty.title');
    },
    lxValuesBlockHonestyText(params: { page_partner_name: string }) {
        return Localization.get(
            'bumblesite@team_bumble.page_life.values_block.honesty.text',
            params
        );
    },
    get lxValuesBlockKindnessTitle() {
        return Localization.get('bumblesite@team_bumble.page_life.values_block.kindness.title');
    },
    lxValuesBlockKindnessText(params: { page_partner_name: string }) {
        return Localization.get(
            'bumblesite@team_bumble.page_life.values_block.kindness.text',
            params
        );
    },
    get lxValuesBlockAccountabilityTitle() {
        return Localization.get(
            'bumblesite@team_bumble.page_life.values_block.accountability.title'
        );
    },
    get lxValuesBlockAccountabilityText() {
        return Localization.get(
            'bumblesite@team_bumble.page_life.values_block.accountability.text'
        );
    },
    get lxValuesBlockInclusivityTitle() {
        return Localization.get('bumblesite@team_bumble.page_life.values_block.inclusivity.title');
    },
    lxValuesBlockInclusivityText(params: { page_partner_name: string }) {
        return Localization.get(
            'bumblesite@team_bumble.page_life.values_block.inclusivity.text',
            params
        );
    },
    lxTestimonialsBadooBlockHeader(params: { page_badoo_name: string }) {
        return Localization.get(
            'bumblesite@team_bumble.page_life.testimonials_badoo_block.header',
            params
        );
    },
    get lxTestimonialsBadooVideoBlockTitle() {
        return Localization.get(
            'bumblesite@team_bumble.page_life.testimonials_badoo_video_block.title'
        );
    },
};
