import React from 'react';
import QuotesCarousel from 'components/quotes-carousel/quotes-carousel-dynamic';
import useMediaQuery from 'lib/use-media-query';

export type QuoteCardType = {
    name: string;
    position: string;
    text: string;
    imageSrc: string;
};

export type PageQuotesCarouselProps = {
    cards: QuoteCardType[];
};

const PageQuotesCarousel: React.FC<PageQuotesCarouselProps> = ({ cards }) => {
    const isBreakpoint = useMediaQuery(800);

    return <QuotesCarousel cards={cards} isSpacious={!isBreakpoint} />;
};

export default PageQuotesCarousel;
