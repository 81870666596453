import React from 'react';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import PageSection from 'components/page/page-section';
import { Columns, ColumnsItem, ColumnsSize } from 'components/columns';
import Caption from 'components/typography/caption';

type PageSectionColumnSize = ColumnsSize;

type PageSectionTwoColumnsPropsType = {
    extraClass?: string;
    mediaQuery?: number;
    title?: React.ReactNode;
    titleCenter?: boolean;
    left?: React.ReactNode;
    right?: React.ReactNode;
    vAlignTop?: boolean;
    leftColumnsSize?: PageSectionColumnSize;
    leftColumnsAlignCenter?: boolean;
    leftLimitedWidth?: boolean;
    rightColumnsSize?: PageSectionColumnSize;
    rightColumnsAlignCenter?: boolean;
    rightLimitedWidth?: boolean;
    isMobileOrderInverted?: boolean;
    equal?: boolean;
    inner?: boolean;
    width?: number | string;
    leftColumnWidth?: number | string;
};
const PageSectionTwoColumns: React.FC<PageSectionTwoColumnsPropsType> = (props) => {
    const {
        extraClass,
        mediaQuery,
        left,
        right,
        vAlignTop,
        leftColumnsSize = ColumnsItem.size['1/1'],
        rightColumnsSize = ColumnsItem.size['1/1'],
        leftColumnsAlignCenter,
        leftLimitedWidth,
        rightColumnsAlignCenter,
        rightLimitedWidth,
        isMobileOrderInverted,
        equal,
        title,
        titleCenter,
        inner,
        width,
        leftColumnWidth,
    } = props;
    const isBreakpoint = useMediaQuery(mediaQuery || MediaQueryWidth['desktop-sm']);

    return (
        <PageSection
            inner={inner}
            splashScreen={true}
            extraClass={extraClass || 'page__section--padded text-extra-space'}
        >
            {title ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '0 auto',
                        marginBottom: isBreakpoint ? 48 : 96,
                        textAlign: titleCenter ? 'center' : undefined,
                    }}
                >
                    <Caption>{title}</Caption>
                </div>
            ) : null}
            <Columns
                vAlignTop={vAlignTop}
                hasMobileLayout={isBreakpoint}
                isMobileOrderInverted={isMobileOrderInverted}
                equal={equal}
                width={width}
            >
                {left ? (
                    <ColumnsItem
                        size={leftColumnsSize}
                        justifyContentCenter={leftColumnsAlignCenter}
                        limitedWidth={leftLimitedWidth}
                        width={leftColumnWidth}
                    >
                        {left}
                    </ColumnsItem>
                ) : null}

                {right ? (
                    <ColumnsItem
                        size={rightColumnsSize}
                        justifyContentCenter={rightColumnsAlignCenter}
                        limitedWidth={rightLimitedWidth}
                    >
                        {right}
                    </ColumnsItem>
                ) : null}
            </Columns>
        </PageSection>
    );
};

export default PageSectionTwoColumns;
export { ColumnsSize as PageSectionColumnSize };
