import loadScript from 'lib/load-script';
import { acceptAllCookies, hasUserAnalyticsConsent } from 'lib/has-cookie-consent';

type VendorConsents = {
    consentedVendors: Array<{ _id: string }>;
};

const GOOGLE_ANALYTICS_ID = '5e542b3a4cd8884eb41b5a72';
const LINKEDIN_ID = '5f1b2fbeb8e05c306f2a1ed6';

const hasConsent = (vendorConsents: VendorConsents, ID: string) =>
    vendorConsents.consentedVendors.some((vendor: { _id: string }) => vendor._id === ID);

const getVendorsData = function () {
    __tcfapi(
        'getCustomVendorConsents',
        2,
        (vendorConsents: VendorConsents, successForConsent: boolean) => {
            if (successForConsent) {
                const hasGoogleAnalyticsConsent = hasConsent(vendorConsents, GOOGLE_ANALYTICS_ID);
                const hasLinkedinConsent = hasConsent(vendorConsents, LINKEDIN_ID);

                if (hasGoogleAnalyticsConsent) {
                    setTimeout(() => {
                        loadScript('/analytics.js', 'analytics');
                    }, 100);
                }

                if (hasLinkedinConsent) {
                    setTimeout(() => {
                        loadScript('/linkedin-analytics.js', 'linkedin-analytics');
                    }, 100);
                }
            }
        }
    );
};

export function handleCookieConsent(
    tcData: { gdprApplies: boolean; eventStatus: string; listenerId: string },
    success: boolean
) {
    if (success) {
        if (
            !tcData.gdprApplies &&
            (tcData.eventStatus === 'tcloaded' || tcData.eventStatus === 'cmpuishown')
        ) {
            // non-gdpr countries
            // set all cookies allowed
            // @TODO add CCPA + Brazil
            setTimeout(() => {
                hasUserAnalyticsConsent(() => {
                    loadScript('/analytics.js', 'analytics');
                    loadScript('/linkedin-analytics.js', 'linkedin-analytics');
                    acceptAllCookies();
                });
            }, 400);
        } else if (tcData.gdprApplies) {
            const cookieContainer: HTMLElement | null =
                document.querySelector('.cookie-notification');

            if (cookieContainer) {
                cookieContainer.style.display = 'none';
            }
            // we call script on each page load, if script loaded - it won't load again
            getVendorsData();
            acceptAllCookies();
        }
    }

    return tcData.listenerId;
}

function displayGDPR(event: Event) {
    event.preventDefault();

    try {
        //@ts-ignore
        window._sp_.gdpr.loadPrivacyManagerModal();
    } catch (error) {
        window.location.href = 'https://bumble.com/cookie-policy';
    }
}

function displayCCPA(event: Event) {
    event.preventDefault();

    try {
        //@ts-ignore
        window._sp_.ccpa.loadPrivacyManagerModal('708248');
    } catch (error) {
        window.location.href = 'https://bumble.com/cookie-policy';
    }
}

export const onUsePrivacyManagerHandler = () => {
    const privacyManagerGDPR = document.querySelector('.js-manage-cookies');
    const privacyManagerCCPA = document.querySelector('.js-ccpa-privacy');

    if (privacyManagerGDPR) {
        privacyManagerGDPR.addEventListener('click', displayGDPR);
    }

    if (privacyManagerCCPA) {
        privacyManagerCCPA.addEventListener('click', displayCCPA);
    }

    return () => {
        if (privacyManagerGDPR) {
            privacyManagerGDPR.removeEventListener('click', displayGDPR);
        }

        if (privacyManagerCCPA) {
            privacyManagerCCPA.removeEventListener('click', displayCCPA);
        }
    };
};
