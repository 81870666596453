import React from 'react';
import { ContentBlock, Maybe } from 'interfaces/team-bumble-microsite-cms';
import { mapAttributesToProps } from 'lib/blocks';
import defaultBlockMap from '../';
import { PostContentBlockMap } from '../index.types';
import UnsupportedBlock from '../UnsupportedBlock/UnsupportedBlock';

type Props = {
    blocks: Maybe<ContentBlock>[];
    blockMapOverrides?: PostContentBlockMap;
};

export default function PostContent({ blocks, blockMapOverrides = {} }: Props) {
    // This is a functional component used to render the related component for each block on PostContent
    //
    // If you want to customize some component or create new ones, you can provide the blockMapOverrides prop to this component
    // with a mapping when you're rendering some page on next.js structure.
    const blockMap: PostContentBlockMap = {
        ...defaultBlockMap,
        ...blockMapOverrides,
    };

    /**
     * @TODO: Info Security risks: when we pass code, we need to make sure we pass
     * only harmless html, so no to:
     * - scripts in any form
     * - styles in any form
     * - js events, such as onClick
     * also we need check how it is implemented in lexemes interface as stage 1.
     * As a stage 2 - we need to support use of pregenerated lexemes in wordpress as plugin
     * - lexemes will be served as json files in separate instance of nginx
     */

    return (
        <>
            {blocks.map((block, i) => {
                if (!block) {
                    return null;
                }

                const attributesProps = mapAttributesToProps(block.attributes || []);
                const defaultProps = { key: `block-${i}`, block };
                const Block = block.name ? blockMap[block.name] : null;

                if (Block) {
                    return <Block {...defaultProps} {...attributesProps} key={i} />;
                }

                // In development, highlight unsupported blocks so that they get
                // visibility with developers.
                if (process.env.NODE_ENV === 'development') {
                    return <UnsupportedBlock {...defaultProps} {...attributesProps} key={i} />;
                }

                // In production, ignore unsupported blocks.
                return null;
            })}
        </>
    );
}
