import React from 'react';
import PageSection from 'components/page/page-section';
import PageSectionTwoColumns, {
    PageSectionColumnSize,
} from '../../components/page-section-two-columns/page-section-two-columns';
import TextSection, {
    TextSectionAlign,
    TextSectionTitleStyle,
} from 'components/text-section/text-section';
import { AnimationObservableAppearance } from 'components/animations/animations';

export type PageSectionIntroOneColumnPropsType = {
    vAlignTop?: boolean;
    caption?: string;
    title?: string;
    text?: string;
    titleStyles?: any;
    titleIsUppercase?: boolean;
    width?: number | string;
    columnWidth?: number | string;
    extraClass?: string;
};

// TODO: one column section behaves as 2 columns?
const PageSectionIntroOneColumn: React.FC<PageSectionIntroOneColumnPropsType> = (props) => {
    const {
        vAlignTop = true,
        title,
        titleStyles,
        caption,
        text,
        width,
        columnWidth,
        extraClass,
        titleIsUppercase,
    } = props;

    const titleStylesProp = titleIsUppercase ? { textTransform: 'uppercase' } : titleStyles;

    return (
        <PageSection theme={PageSection.Theme.NONE} isFullWidth={true} extraClass={extraClass}>
            <PageSectionTwoColumns
                vAlignTop={vAlignTop}
                left={
                    <AnimationObservableAppearance>
                        <TextSection
                            align={TextSectionAlign.CENTERED}
                            caption={caption}
                            title={title}
                            titleStyles={titleStylesProp}
                            titleStyle={TextSectionTitleStyle.H1}
                            text={text}
                            textCenter={true}
                            textMarginTop={66}
                        />
                    </AnimationObservableAppearance>
                }
                leftColumnsSize={PageSectionColumnSize['1/1']}
                leftColumnsAlignCenter={true}
                extraClass="page__section--padded"
                width={width}
                leftColumnWidth={columnWidth}
            />
        </PageSection>
    );
};

export default PageSectionIntroOneColumn;
