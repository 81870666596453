import React from 'react';
import cx from 'classnames';
import Image from 'lib/image-container';

export type QuotesCarouselCardProps = {
    text: React.ReactNode;
    name: React.ReactNode;
    position: React.ReactNode;
    imageSrc: string;
    alt?: string;
    isSpacious?: boolean;
};

function QuotesCarouselCard(props: QuotesCarouselCardProps) {
    const className = cx({
        'quotes-carousel__card': true,
        'quotes-carousel__card--spacious': props.isSpacious,
    });

    return (
        <div className={className}>
            <div className="quotes-carousel__text">
                <div className="quotes-carousel__decoration">
                    <svg viewBox="0 0 76 28">
                        <path
                            d="M50.1 28l7.4-14V.4H42.3v14.8h5.3l-4 12.8H50zM68.6 28L76 14V.4H60.8v14.8h5.3l-4 12.8h6.5zM25.9 0l-7.4 14v13.6h15.2V12.8h-5.3l4-12.8H26zM7.4 0L0 14v13.6h15.2V12.8H9.9l4-12.8H7.4z"
                            fill="var(--color-primary)"
                        />
                    </svg>
                </div>

                {props.text}
            </div>

            <div className="quotes-carousel__profile">
                <div className="quotes-carousel__photo">
                    <Image alt={props.alt || ''} src={props.imageSrc} fill={true} />
                </div>
                <div className="quotes-carousel__info">
                    <div className="quotes-carousel__name">{props.name}</div>

                    <div className="quotes-carousel__position">{props.position}</div>
                </div>
            </div>
        </div>
    );
}

export default QuotesCarouselCard;
