import React from 'react';
import { BlockProps } from '../index.types';
import PageVideoSection, {
    PageVideoSectionProps,
    VideoListItem,
    VideoList,
} from 'components/page-video-section/page-video-section-dynamic';
import { decode } from 'lib/decode';

const INIT_VIDEO_ITEM = {
    id: '',
    url: '',
    imageSrc: '',
    videoUrl: '',
};

function setVideoList(data: BlockProps['block']['innerBlocks']) {
    const list: VideoList = [];

    data?.forEach((innerBlock) => {
        const listItem: VideoListItem = Object.create(INIT_VIDEO_ITEM);

        const id = innerBlock?.attributes?.filter((attribute) => attribute?.name === 'id')[0]
            ?.value;

        if (id) {
            listItem.id = id;
        }

        const videoUrl = innerBlock?.attributes?.filter(
            (attribute) => attribute?.name === 'videoUrl'
        )[0]?.value;

        if (videoUrl) {
            listItem.url = videoUrl;
        }

        const imageSrc = innerBlock?.attributes?.filter(
            /**
             * @TODO: change to imageSrc in BE as well after release
             */
            (attribute) => attribute?.name === 'imageUrl'
        )[0]?.value;

        if (imageSrc) {
            /**
             * @TODO: add fallback to youtube preview on BE
             */
            listItem.imageSrc = imageSrc || '';
        }

        list.push(listItem);
    });

    return list;
}

type PageVideoSectionType = BlockProps &
    PageVideoSectionProps & {
        isSecondaryBg?: 'true' | 'false';
    };

export default function PageVideoSectionBlock({ block, ...props }: PageVideoSectionType) {
    const videos = setVideoList(block.innerBlocks);

    return (
        <PageVideoSection
            title={decode(props.title)}
            text={decode(props.text)}
            videoPosition={props.videoPosition || 'left'}
            brand={props.brand || 'bumble'}
            videos={videos}
            isSecondary={props.isSecondaryBg === 'true'}
        />
    );
}
