import React from 'react';
import cx from 'classnames';

type Props = {
    children?: React.ReactNode[];
    copyright?: string | React.ReactNode;
    columns?: React.ReactNode[];
};

function Footer(props: Props) {
    const { children, copyright, columns } = props;

    const className = cx({
        footer__inner: true,
        'footer__inner--columns': columns,
    });

    const innerElements = React.Children.map(children, (child) => (
        <div className="footer__inner-item footer__inner-item--column">{child}</div>
    ));

    const innerColumns = React.Children.map(columns, (column) => (
        <div className="footer__inner-item footer__inner-item--column">{column}</div>
    ));

    return (
        <div className="footer">
            <div className={className}>{columns ? innerColumns : innerElements}</div>

            {copyright ? <div className="footer__copyright">{copyright}</div> : null}
        </div>
    );
}

export default Footer;
