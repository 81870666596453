import React from 'react';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import PageSectionTwoColumns from 'components/page-section-two-columns/page-section-two-columns';
import TextSection, { TextSectionTitleStyle } from 'components/text-section/text-section';
import ColumnMediaCard from 'components/column-media-card/column-media-card';
import ColumnMediaCardWrapper from 'components/column-media-card/column-media-card-wrapper';
import { MobileCaption } from 'components/typography';

export type PageLifeSectionProps = {
    caption?: string;
    title: string;
    text: string;
    extraText?: string;
    imageSrc: string;
    imageAspectRatio?: number;
    imagePosition?: 'left' | 'right';
    mediaquery?: MediaQueryWidth;
    brand?: 'bumble' | 'badoo';
};

const PageLifeSection: React.FC<PageLifeSectionProps> = (props) => {
    const {
        caption,
        title,
        text,
        extraText,
        imageSrc,
        imagePosition = 'left',
        mediaquery = 640,
        brand = 'bumble',
    } = props;

    const isBreakpoint = useMediaQuery(mediaquery);
    const imageAspectRatio = 277 / 199;

    const textBlock = (
        <TextSection
            caption={isBreakpoint ? undefined : caption}
            titleStyle={TextSectionTitleStyle.H2}
            titleCenter={isBreakpoint}
            title={title}
            text={text}
            extraText={extraText}
        />
    );

    const imageBlock = (
        <>
            {isBreakpoint && caption ? <MobileCaption>{caption}</MobileCaption> : null}
            <ColumnMediaCardWrapper maxWidth="585px">
                <ColumnMediaCard
                    hasShadow={true}
                    src={imageSrc}
                    aspectRatio={imageAspectRatio}
                    // @TODO make it as prop to show text over image
                    // title={isBreakpoint ? title : undefined}
                />
            </ColumnMediaCardWrapper>
        </>
    );

    return (
        <PageSectionTwoColumns
            extraClass={`page__section--padded ${brand}`}
            isMobileOrderInverted={imagePosition === 'right'}
            right={textBlock}
            left={imageBlock}
        />
    );
};

export default PageLifeSection;
