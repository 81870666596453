import React, { useEffect, useState, useCallback, useContext } from 'react';
import cx from 'classnames';
import Link from 'components/link/link';
import Button from 'components/button/button';
import { Lexeme } from '@bumble/localization/components';
import LocaleContext from 'lib/locale-context';
import { acceptAllCookies, getCookieSettings } from 'lib/has-cookie-consent';
import { routes } from 'lib/constants-team-bumble';

type Props = {
    ctaLink?: string;
    title?: string | React.ReactNode;
    text?: string;
};

function CookieNotification(props: Props) {
    const lang = useContext(LocaleContext);

    const { ctaLink = `https://bumble.com/${lang}${routes.cookie_policy}`, title, text } = props;

    const className = {
        block: cx({
            'cookie-notification': true,
        }),
        closeAction: cx({
            'cookie-notification__close': true,
        }),
    };

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const cookieConsentProvided = Boolean(getCookieSettings());
        const showCookieBanner = () => {
            setIsVisible(true);
            global.document.body.classList.add('show-cookie-notification');
        };

        if (!cookieConsentProvided) {
            showCookieBanner();
        }
    }, []);

    const hideCookieBannerWithAccept = useCallback(() => {
        setIsVisible(false);
        acceptAllCookies();
        global.document.body.classList.remove('show-cookie-notification');
    }, []);

    return (
        <div className={className.block} style={{ display: isVisible ? 'block' : 'none' }}>
            <div className="cookie-notification__inner">
                {title ? <div className="cookie-notification__title">{title}</div> : null}

                <div
                    className="cookie-notification__text"
                    dangerouslySetInnerHTML={
                        text
                            ? {
                                  __html: text,
                              }
                            : undefined
                    }
                >
                    {text ? null : (
                        <>
                            <Lexeme path="bma_client@cookie-notification.text" />{' '}
                            <Link
                                className="cookie-notification__link"
                                href={ctaLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Lexeme path="settings@cookies.footer.cookie_policy" />
                            </Link>
                        </>
                    )}
                </div>

                <div className="cookie-notification__actions">
                    <div className="cookie-notification__action">
                        <Button
                            size={Button.size.SMALL}
                            designType={Button.designType.TRANSPARENT}
                            color={Button.color.Primary}
                            target="_blank"
                            href={routes.cookie_policy_functional_anchor}
                            text={
                                <Lexeme path="bumblesite@team_bumble.page_index.cookie.banner.manage_options" />
                            }
                        />
                    </div>

                    <div className="cookie-notification__action">
                        <Button
                            size={Button.size.SMALL}
                            designType={Button.designType.FILLED}
                            color={Button.color.Primary}
                            jsClass="js-cookie-accept-all"
                            text={
                                <Lexeme path="bumblesite@team_bumble.page_index.cookie.banner.accept_all" />
                            }
                            onClick={hideCookieBannerWithAccept}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CookieNotification;
