import React from 'react';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import PageSectionTwoColumns from 'components/page-section-two-columns/page-section-two-columns';
import TextSection, {
    TextSectionAlign,
    TextSectionTitleStyle,
} from 'components/text-section/text-section';
import ColumnMediaCard from 'components/column-media-card/column-media-card';
import ColumnMediaCardWrapper from 'components/column-media-card/column-media-card-wrapper';
import Button from 'components/button/button';
import ButtonWrapper from 'components/button/button-wrapper';

export type PageTextImageSectionProps = {
    title: string;
    text: string;
    extraText?: string;
    ctaText?: string;
    ctaLink?: string;
    imageSrc: string;
    imagePosition?: 'left' | 'right';
    imageAspectRatio?: number;
    mediaquery?: MediaQueryWidth;
    isSecondary?: boolean;
    openInNewWindow?: boolean;
};

const PageTextImageSection: React.FC<PageTextImageSectionProps> = (props) => {
    const {
        title,
        text,
        extraText,
        ctaText,
        ctaLink,
        imageSrc,
        imagePosition = 'left',
        imageAspectRatio,
        mediaquery = 640,
        isSecondary,
        openInNewWindow,
    } = props;
    const isBreakpoint = useMediaQuery(mediaquery);
    const imageAspectRatioDefault = isBreakpoint ? 180 / 314 : 466 / 700;
    const textBlock = (
        <>
            <TextSection
                title={isBreakpoint ? undefined : title}
                // @TODO make it changable within all sections
                titleStyle={TextSectionTitleStyle.H2}
                text={text}
                extraText={extraText}
            />
            {ctaLink && ctaText ? (
                <ButtonWrapper>
                    <Button
                        href={ctaLink}
                        text={ctaText}
                        // @TODO make it changable within all sections
                        target={openInNewWindow ? '_blank' : '_self'}
                        designType={Button.designType.STROKE}
                        color={Button.color.Primary}
                        size={Button.size.LARGE}
                        inline={true}
                        // @TODO make it changable within all sections - ctaFixedWidth
                        extraClass="button--fixed-width"
                    />
                </ButtonWrapper>
            ) : null}
        </>
    );
    // @TODO rename / move content?
    const imageBlock = (
        <>
            {isBreakpoint ? (
                <TextSection
                    // @TODO make it changable within all sections
                    align={TextSectionAlign.CENTERED}
                    title={title}
                    titleStyle={TextSectionTitleStyle.H2}
                    // @TODO make it changable within all sections
                    titleStyles={{
                        marginBottom: 50,
                    }}
                />
            ) : null}
            <ColumnMediaCardWrapper
                maxHeight={isBreakpoint ? '314px' : '700px'}
                maxWidth={isBreakpoint ? '180px' : '466px'}
                marginBottom={isBreakpoint ? '78px' : undefined}
            >
                <ColumnMediaCard
                    hasShadow={true}
                    src={imageSrc}
                    aspectRatio={imageAspectRatio || imageAspectRatioDefault}
                    // @TODO make it as prop to show text over image
                    // title={isBreakpoint ? title : undefined}
                />
            </ColumnMediaCardWrapper>
        </>
    );

    return (
        <PageSectionTwoColumns
            extraClass={`page__section--padded ${isSecondary ? 'page__section--secondary' : null}`}
            isMobileOrderInverted={imagePosition === 'right'}
            right={textBlock}
            left={imageBlock}
        />
    );
};

export default PageTextImageSection;
