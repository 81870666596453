import React from 'react';
import { BlockProps } from '../index.types';
import PageImageTextSection, {
    PageImageTextSectionProps,
} from 'components/page-section-two-columns/page-image-text-section';
import { decode, decodePossible } from 'lib/decode';

export default function PageImageTextSectionBlock({
    block,
    ...props
}: BlockProps & PageImageTextSectionProps) {
    return (
        <PageImageTextSection
            title={decode(props.title)}
            text={decode(props.text)}
            imageSrc={props.imageSrc}
            imageAspectRatio={props.imageAspectRatio}
            imagePosition={props.imagePosition || 'left'}
            ctaLink={props.ctaLink}
            ctaText={decodePossible(props.ctaText)}
            openInNewWindow={props.openInNewWindow}
        />
    );
}
