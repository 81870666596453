import React from 'react';
import { BlockProps } from '../index.types';
import PageLifeSection, {
    PageLifeSectionProps,
} from 'components/page-life-section/page-life-section';
import { decode, decodePossible } from 'lib/decode';

type PageLifeSectionType = BlockProps & PageLifeSectionProps;

export default function PageLifeSectionBlock({ block, ...props }: PageLifeSectionType) {
    return (
        <PageLifeSection
            caption={decodePossible(props.caption)}
            title={decode(props.title)}
            text={decode(props.text)}
            extraText={decodePossible(props.extraText)}
            imageSrc={props.imageSrc}
            imageAspectRatio={props.imageAspectRatio}
            imagePosition={props.imagePosition || 'left'}
            brand={props.brand || 'bumble'}
        />
    );
}
