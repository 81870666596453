import * as React from 'react';
import { Pagination, Virtual, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Icon from 'components/icon/icon';
import cx from 'classnames';

type SliderProps = typeof Swiper & {
    index?: number;
    arrowColorSecondary?: boolean;
    children: React.ReactNode;
};

const SliderWithNavigation: React.FC<React.PropsWithChildren<SliderProps>> = (props) => {
    const { arrowColorSecondary, ...sliderProps } = props;

    const slides = React.Children.toArray(props.children).filter(Boolean);
    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);
    // swiper__arrow--secondary

    if (!props.children) {
        return null;
    }

    const className = {
        arrowLeft: cx({
            swiper__arrow: true,
            'swiper__arrow--left': true,
            'swiper__arrow--secondary': arrowColorSecondary,
        }),
        arrowRight: cx({
            swiper__arrow: true,
            'swiper__arrow--right': true,
            'swiper__arrow--secondary': arrowColorSecondary,
        }),
    };

    return (
        <Swiper
            {...sliderProps}
            modules={[Pagination, Virtual, Navigation]}
            loop={true}
            // technically we don't need it, but is more safe
            navigation={{ prevEl: '.swiper__arrow--left', nextEl: '.swiper__arrow--right' }}
            onInit={(swiper) => {
                if (props.index) {
                    swiper.slideToLoop(props.index);
                }
            }}
            // on
        >
            {slides.map((slideContent, index) => (
                <SwiperSlide key={index} virtualIndex={index}>
                    {slideContent}
                </SwiperSlide>
            ))}

            <span slot="container-end" className={className.arrowLeft} ref={navigationPrevRef}>
                <span className="swiper__arrow-icon">
                    <Icon size="stretch" name={Icon.Name.GenericChevronBackStroke} />
                </span>
            </span>

            <span slot="container-end" className={className.arrowRight} ref={navigationNextRef}>
                <span className="swiper__arrow-icon">
                    <Icon size="stretch" name={Icon.Name.GenericChevronForwardStroke} />
                </span>
            </span>
        </Swiper>
    );
};

export default SliderWithNavigation;
