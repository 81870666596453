import React from 'react';
import { Lexeme } from '@bumble/localization/components';
import Footer from 'components/footer/footer';
import FooterMenu from 'components/footer/footer-menu';
import FooterGridMenu from 'components/footer/footer-grid-menu';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import { getFooterMenuItemsTeamBumble } from 'lib/constants-team-bumble';
import CtaBox, { CtaBoxAlign } from 'components/cta-box/cta-box';
import CtaBoxTitle, { CtaBoxTitleAlign } from 'components/cta-box/cta-box-title';
import CtaBoxMedia, { CtaBoxMediaAlign } from 'components/cta-box/cta-box-media';
import Header1 from 'components/typography/header-1';
import Header2 from 'components/typography/header-2';
import Button from 'components/button/button';
import HeroBlock from 'components/hero-block/hero-block';
import AppsLinks from 'components/apps-links/apps-links';
import { AnimationObservableAppearance } from 'components/animations/animations';
import { Maybe } from 'interfaces/team-bumble-microsite-cms';
import GeoContext from 'lib/geo-context';

type PageFooterPropsType = {
    hasCta?: boolean;
    footerTitleText?: Maybe<string> | undefined;
    hasFooterCtaButton?: boolean;
    ctaButtonText?: Maybe<string> | undefined;
    ctaButtonUrl?: Maybe<string> | undefined;
    ctaButtonTargetNewWindow?: boolean;
};

const PageFooter: React.FC<PageFooterPropsType> = (props) => {
    const {
        hasCta = true,
        footerTitleText,
        hasFooterCtaButton,
        ctaButtonText,
        ctaButtonUrl,
        ctaButtonTargetNewWindow,
    } = props;
    const country = React.useContext(GeoContext);
    const isBreakpointMobileDevice = useMediaQuery();
    const isBreakpointDesktopDevice = useMediaQuery(MediaQueryWidth['desktop-xlg']);
    const headerStyle = {
        maxWidth: isBreakpointDesktopDevice ? 570 : 950,
        margin: '0 auto',
    };
    const footerMenuItemsTeamBumble = getFooterMenuItemsTeamBumble(country);

    const footerColumns = [
        <FooterGridMenu key="left">
            {footerMenuItemsTeamBumble.left.map((column, index) => (
                <FooterMenu
                    {...column}
                    key={`column-left-${index}`}
                    appsLinks={
                        !isBreakpointMobileDevice && index === 0 ? (
                            <AppsLinks variant="compact" color="base" />
                        ) : undefined
                    }
                />
            ))}
        </FooterGridMenu>,
        <FooterGridMenu key="right">
            {footerMenuItemsTeamBumble.right.map((column, index) => (
                <FooterMenu {...column} key={`column-right-${index}`} />
            ))}
        </FooterGridMenu>,
    ];

    if (isBreakpointMobileDevice) {
        footerColumns.push(
            <FooterGridMenu key="right-extra">
                <AppsLinks variant="compact" color="base" />
            </FooterGridMenu>
        );
    }

    return (
        <>
            {hasCta ? (
                <div style={{ marginBottom: isBreakpointMobileDevice ? 0 : 80 }}>
                    <AnimationObservableAppearance>
                        <HeroBlock
                            isFluid={true}
                            title={
                                <CtaBox align={CtaBoxAlign.CENTERED}>
                                    {footerTitleText ? (
                                        <CtaBoxTitle align={CtaBoxTitleAlign.CENTERED}>
                                            {isBreakpointMobileDevice ? (
                                                <Header2
                                                    color="white"
                                                    // eslint-disable-next-line react/forbid-component-props
                                                    style={headerStyle}
                                                    html={footerTitleText}
                                                />
                                            ) : (
                                                <Header1
                                                    color="white"
                                                    // eslint-disable-next-line react/forbid-component-props
                                                    style={headerStyle}
                                                    html={footerTitleText}
                                                />
                                            )}
                                        </CtaBoxTitle>
                                    ) : (
                                        <></>
                                    )}

                                    {hasFooterCtaButton && ctaButtonText && ctaButtonUrl ? (
                                        <CtaBoxMedia
                                            align={CtaBoxMediaAlign.CENTERED}
                                            marginTop={isBreakpointMobileDevice ? 42 : 60}
                                        >
                                            <Button
                                                text={ctaButtonText}
                                                href={ctaButtonUrl}
                                                target={
                                                    ctaButtonTargetNewWindow ? '_blank' : '_self'
                                                }
                                                color={Button.color.Primary}
                                                designType={Button.designType.FILLED}
                                                size={Button.size.LARGE}
                                            />
                                        </CtaBoxMedia>
                                    ) : (
                                        <></>
                                    )}
                                </CtaBox>
                            }
                        />
                    </AnimationObservableAppearance>
                </div>
            ) : null}

            <Footer
                copyright={
                    <Lexeme
                        path="corp@copyright_notice"
                        params={{ current_year: new Date().getFullYear() }}
                    />
                }
                columns={footerColumns}
            />
        </>
    );
};

export default PageFooter;
