import React from 'react';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import PageSectionTwoColumns from 'components/page-section-two-columns/page-section-two-columns';
import TextSection from 'components/text-section/text-section';
import ColumnMediaCard from 'components/column-media-card/column-media-card';
import ColumnMediaCardWrapper from 'components/column-media-card/column-media-card-wrapper';

export type PageTitleImageTextSectionProps = {
    title: string;
    text: string;
    extraText?: string;
    imageSrc: string;
    imagePosition?: 'left' | 'right';
    mediaquery?: MediaQueryWidth;
    brand?: 'bumble' | 'badoo';
};

const PageTitleImageTextSection: React.FC<PageTitleImageTextSectionProps> = (props) => {
    const {
        title,
        text,
        extraText,
        imageSrc,
        imagePosition = 'left',
        mediaquery = 640,
        brand = 'bumble',
    } = props;

    const isBreakpoint = useMediaQuery(mediaquery);
    const imageAspectRatio = isBreakpoint ? 180 / 314 : 466 / 700;
    const textBlock = <TextSection title={title} text={text} extraText={extraText} />;

    const imageBlock = (
        <ColumnMediaCardWrapper
            maxHeight={isBreakpoint ? '314px' : '700px'}
            maxWidth={isBreakpoint ? '180px' : '466px'}
        >
            <ColumnMediaCard
                hasShadow={true}
                src={imageSrc}
                aspectRatio={imageAspectRatio}
                // @TODO make it as prop to show text over image
                // title={isBreakpoint ? title : undefined}
            />
        </ColumnMediaCardWrapper>
    );

    return (
        <PageSectionTwoColumns
            extraClass={`page__section--padded ${brand}`}
            isMobileOrderInverted={imagePosition === 'right'}
            right={textBlock}
            left={imageBlock}
        />
    );
};

export default PageTitleImageTextSection;
