import React from 'react';
import { BlockProps } from '../index.types';
import PageAboutIntroSection, {
    PageHeroBlockAboutPropsType,
} from 'components/page-hero-block/page-hero-block-about-dynamic';
import { decode, decodePossible } from 'lib/decode';

type PageAboutIntroSectionBlockType = {
    block: BlockProps;
} & PageHeroBlockAboutPropsType & {
        isFullScreenMode: 'true' | 'false';
    };

export default function PageAboutIntroSectionBlock({
    block,
    ...props
}: PageAboutIntroSectionBlockType) {
    return (
        <PageAboutIntroSection
            isFullScreen={props.isFullScreenMode === 'true'}
            title={decode(props.title)}
            extraTitle={decodePossible(props.extraTitle)}
            firstImageSrc={props.firstImageSrc}
            secondImageSrc={props.secondImageSrc}
        />
    );
}
