import React from 'react';
import HeroImage from 'components/hero-image/hero-image';
import useMediaQuery from 'lib/use-media-query';
// import useDimensions from 'lib/use-dimensions';

export type PageSectionFullscreenImagePropsType = {
    src?: string;
    srcPortrait?: string;
    aspectRatio?: number;
    content?: React.ReactNode;
};

// TODO: maybe use useDimensions
const PageSectionFullscreenImage: React.FC<PageSectionFullscreenImagePropsType> = (props) => {
    const isBreakpoint = useMediaQuery();
    // const { height, width } = useDimensions();
    let imageSrc = props.src ? props.src : '';
    if (isBreakpoint && props.srcPortrait) {
        imageSrc = props.srcPortrait;
    }

    return (
        <HeroImage
            src={imageSrc}
            aspectRatio={props.aspectRatio}
            overlay={props.content}
            hasParallax={!isBreakpoint}
        />
    );
};

export default PageSectionFullscreenImage;
