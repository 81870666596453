import React from 'react';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import PageSectionTwoColumns from 'components/page-section-two-columns/page-section-two-columns';
import TextSection from 'components/text-section/text-section';
import ColumnMediaCard from 'components/column-media-card/column-media-card';
import ColumnMediaCardWrapper from 'components/column-media-card/column-media-card-wrapper';
import Button from 'components/button/button';
import ButtonWrapper from 'components/button/button-wrapper';

const IMAGE_ASPECT_RATIO = 277 / 199;

export type PageImageTextSectionProps = {
    title: string;
    text: string;
    ctaText?: string;
    ctaLink?: string;
    imageSrc: string;
    imagePosition?: 'left' | 'right';
    imageAspectRatio?: number;
    mediaquery?: MediaQueryWidth;
    openInNewWindow?: boolean;
};

const PageImageTextSection: React.FC<PageImageTextSectionProps> = (props) => {
    const {
        title,
        text,
        ctaText,
        ctaLink,
        imageSrc,
        imagePosition = 'left',
        mediaquery = 640,
        imageAspectRatio = IMAGE_ASPECT_RATIO,
        openInNewWindow,
    } = props;

    const isBreakpoint = useMediaQuery(mediaquery);

    const textBlock = (
        <>
            <TextSection title={!isBreakpoint ? title : undefined} text={text} />

            {ctaLink && ctaText ? (
                <ButtonWrapper>
                    <Button
                        href={ctaLink}
                        text={ctaText}
                        target={openInNewWindow ? '_blank' : '_self'}
                        designType={Button.designType.STROKE}
                        color={Button.color.Primary}
                        size={Button.size.LARGE}
                        inline={true}
                    />
                </ButtonWrapper>
            ) : null}
        </>
    );

    const imageBlock = (
        <ColumnMediaCardWrapper>
            <ColumnMediaCard
                hasShadow={true}
                src={imageSrc}
                aspectRatio={imageAspectRatio}
                title={isBreakpoint ? title : undefined}
            />
        </ColumnMediaCardWrapper>
    );

    return (
        <PageSectionTwoColumns
            extraClass="page__section--padded"
            isMobileOrderInverted={imagePosition === 'right'}
            right={textBlock}
            left={imageBlock}
        />
    );
};

export default PageImageTextSection;
