import React from 'react';
import MediaCard from 'components/media-card/media-card';
import Icon from 'components/icon/icon';
import Slider from 'components/slider/slider';
import useMediaQuery from 'lib/use-media-query';

type Video = {
    id: string;
    url: string;
    imageSrc: string;
    onClick?: (event?: React.MouseEvent<HTMLDivElement>) => void;
};

type PageVideoCarouselWithModalPropsType = {
    videoList: Video[];
};
const PageVideoCarouselWithModal: React.FC<PageVideoCarouselWithModalPropsType> = ({
    videoList,
}) => {
    const isBreakpoint = useMediaQuery(640);

    return (
        <div className="video-carousel">
            <div className="video-carousel__list">
                <Slider
                    className="swiper swiper--arrows-inside"
                    loop={true}
                    spaceBetween={20}
                    videoCarousel={true}
                    navigation={true}
                    // hashNavigation={true}
                    arrowColorSecondary={true}
                    // arrowsLayout={ArrowsLayout.Relative}
                >
                    {videoList.map((item, index) => {
                        return (
                            <div
                                className="video-carousel__card"
                                key={`${item.url}${index}`}
                                data-test="video-carousel-card"
                                data-video={item.url}
                            >
                                <MediaCard
                                    src={item.imageSrc}
                                    width={isBreakpoint ? 277 : 710}
                                    height={isBreakpoint ? 148 : 379}
                                    layout="responsive"
                                />
                                <div className="video-carousel__action" onClick={item.onClick}>
                                    <div className="video-carousel__play-button">
                                        <Icon
                                            name={Icon.Name.MediaPlayCircleHollow}
                                            size="stretch"
                                            color="primary"
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

export default PageVideoCarouselWithModal;
