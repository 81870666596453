import React from 'react';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import PageSectionTwoColumns from 'components/page-section-two-columns/page-section-two-columns';
import TextSection, { TextSectionTitleStyle } from 'components/text-section/text-section';
import ColumnMediaCard from 'components/column-media-card/column-media-card';
import ColumnMediaCardWrapper from 'components/column-media-card/column-media-card-wrapper';

export type PageTwoTextsImageSectionProps = {
    title: string;
    text: string;
    extraText?: string;
    secondTitle?: string;
    secondText?: string;
    secondExtraText?: string;
    imageSrc: string;
    imagePosition?: 'left' | 'right';
    imageAspectRatio?: number;
    mediaquery?: MediaQueryWidth;
    brand?: 'bumble' | 'badoo';
    isSecondary?: boolean;
};

const PageTwoTextsImageSection: React.FC<PageTwoTextsImageSectionProps> = (props) => {
    const {
        title,
        text,
        extraText,
        imageSrc,
        imagePosition = 'left',
        imageAspectRatio,
        mediaquery = 640,
        brand = 'bumble',
        secondText,
        secondTitle,
        secondExtraText,
        isSecondary,
    } = props;

    const isBreakpoint = useMediaQuery(mediaquery);
    const imageAspectRatioDefault = isBreakpoint ? 180 / 314 : 466 / 700;
    const imageElementBlock = (
        <ColumnMediaCard
            hasShadow={true}
            src={imageSrc}
            aspectRatio={imageAspectRatio || imageAspectRatioDefault}
            // @TODO make it as prop to show text over image
            // title={isBreakpoint ? title : undefined}
        />
    );
    const textBlock = (
        <>
            <TextSection
                title={title}
                text={text}
                extraText={extraText}
                // @TODO make it changable within all sections
                titleStyle={TextSectionTitleStyle.H2}
            />
            {isBreakpoint ? <div style={{ margin: '70px 0' }}>{imageElementBlock}</div> : null}

            <TextSection
                title={secondTitle}
                text={secondText}
                extraText={secondExtraText}
                // @TODO make it changable within all sections
                titleStyle={TextSectionTitleStyle.H2}
                titleStyles={{ marginTop: isBreakpoint ? undefined : 100 }}
            />
        </>
    );
    const imageBlock = (
        <ColumnMediaCardWrapper
            maxHeight={isBreakpoint ? '314px' : '700px'}
            maxWidth={isBreakpoint ? '180px' : '466px'}
            marginBottom={isBreakpoint ? '78px' : undefined}
        >
            {imageElementBlock}
        </ColumnMediaCardWrapper>
    );

    return (
        <PageSectionTwoColumns
            extraClass={`page__section--padded ${brand} ${
                isSecondary ? 'page__section--secondary' : null
            }`}
            isMobileOrderInverted={imagePosition === 'right'}
            right={textBlock}
            left={isBreakpoint ? null : imageBlock}
        />
    );
};

export default PageTwoTextsImageSection;
