import React from 'react';
import PageSection from 'components/page/page-section';
import PageSectionTwoColumns from '../../components/page-section-two-columns/page-section-two-columns';
import TextSection, { TextSectionTitleStyle } from 'components/text-section/text-section';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import { AnimationObservableAppearance } from 'components/animations/animations';

type PageSectionIntroTwoColumnsPropsType = {
    introSection?: boolean;
    vAlignTop?: boolean;
    leftCaption?: string;
    leftTitle?: string;
    rightText?: string;
    rightExtraText?: string;
    titleStyles?: any;
    titleIsUppercase?: boolean;
};
const PageSectionIntroTwoColumns: React.FC<PageSectionIntroTwoColumnsPropsType> = (props) => {
    const {
        introSection,
        vAlignTop = true,
        leftTitle,
        leftCaption,
        rightText,
        rightExtraText,
        titleStyles,
        titleIsUppercase,
    } = props;
    const isBreakpoint = useMediaQuery(MediaQueryWidth['desktop-sm']);
    const titleStylesProp = titleIsUppercase ? { textTransform: 'uppercase' } : titleStyles;
    return (
        <PageSection
            theme={PageSection.Theme.NONE}
            isFullWidth={true}
            extraClass="page__section--padded"
        >
            <PageSectionTwoColumns
                vAlignTop={vAlignTop}
                left={
                    <AnimationObservableAppearance>
                        <TextSection
                            caption={leftCaption}
                            title={leftTitle}
                            titleStyle={introSection ? TextSectionTitleStyle.H1 : undefined}
                            titleStyles={titleStylesProp}
                        />
                    </AnimationObservableAppearance>
                }
                right={
                    <TextSection
                        // @todo to find less sophisticated solution
                        caption={'&nbsp;'}
                        text={rightText}
                        extraText={rightExtraText}
                    />
                }
                extraClass="page__section--padded"
                leftColumnsAlignCenter={isBreakpoint}
            />
        </PageSection>
    );
};

export default PageSectionIntroTwoColumns;
