import React from 'react';
import Text, { TextType, TextProps } from 'components/typography/text';

const P2: React.FC<React.PropsWithChildren<TextProps>> = (props) => (
    <Text type={TextType.P2} isMedium={props.isMedium} {...props}>
        {props.children}
    </Text>
);

export default P2;
