import { PostContentBlockMap } from 'components/Blocks/index.types';
// import Paragraph from 'components/Blocks/Paragraph/Paragraph';
import PageAnimatedTextSection from 'components/Blocks/PageAnimatedTextSection/PageAnimatedTextSection';
import PageSectionTwoColumns from 'components/Blocks/PageSectionIntroTwoColumns/PageSectionIntroTwoColumns';
import PageImageTextSection from 'components/Blocks/PageImageTextSection/PageImageTextSection';
import PageTextImageSection from 'components/Blocks/PageTextImageSection/PageTextImageSection';
import PageTwoTextImageSection from 'components/Blocks/PageTwoTextImageSection/PageTwoTextImageSection';
import PageTitleImageTextSection from 'components/Blocks/PageTitleImageTextSection/PageTitleImageTextSection';
import BenefitOfficeCardsList from 'components/Blocks/BenefitOfficeCardsList/BenefitOfficeCardsList';
import PageSectionIntroOneColumn from 'components/Blocks/PageSectionIntroOneColumn/PageSectionIntroOneColumn';
import HeroImage from 'components/Blocks/HeroImage/HeroImage';
import HeroImageAboutPage from 'components/Blocks/HeroImage/HeroImageAboutPage';
import PageSection from 'components/Blocks/PageSection/PageSection';
import BenefitsSection from 'components/Blocks/BenefitSection/BenefitSection';
import PageAboutIntroSection from 'components/Blocks/PageAboutIntroSection/PageAboutIntroSection';
import PageAboutAppSection from 'components/Blocks/PageAboutAppSection/PageAboutAppSection';
import PageLifeSection from 'components/Blocks/PageLifeSection/PageLifeSection';
import PageVideoSection from 'components/Blocks/PageVideoSection/PageVideoSection';
import PageValuesSection from 'components/Blocks/PageValuesSection/PageValuesSection';
import PageQuotesCarouselSectionBlock from 'components/Blocks/PageQuotesCarouselSection/PageQuotesCarouselSection';
import SuccessStories from 'components/Blocks/SuccessStories/SuccessStories';
import InterviewSection from 'components/Blocks/InterviewSection/InterviewSection';

const defaultBlockMap: PostContentBlockMap = {
    // 'core/paragraph': Paragraph,
    'team-bumble-ui-blocks/page-animated-text-section': PageAnimatedTextSection,
    'team-bumble-ui-blocks/page-text-image-section': PageTextImageSection,
    'team-bumble-ui-blocks/page-two-texts-image-section': PageTwoTextImageSection,
    'team-bumble-ui-blocks/intro-section-two-columns': PageSectionTwoColumns,
    'team-bumble-ui-blocks/page-image-text-section': PageImageTextSection,
    'team-bumble-ui-blocks/page-title-image-text-section': PageTitleImageTextSection,
    'team-bumble-ui-blocks/intro-section-one-column': PageSectionIntroOneColumn,
    'team-bumble-ui-blocks/office-cards-list': BenefitOfficeCardsList,
    'team-bumble-ui-blocks/hero-image': HeroImage,
    'team-bumble-ui-blocks/hero-image-about-page': HeroImageAboutPage,
    'team-bumble-ui-blocks/page-section': PageSection,
    'team-bumble-ui-blocks/benefits-list': BenefitsSection,
    'team-bumble-ui-blocks/page-about-intro-section': PageAboutIntroSection,
    'team-bumble-ui-blocks/page-about-app-section': PageAboutAppSection,
    'team-bumble-ui-blocks/page-life-section': PageLifeSection,
    'team-bumble-ui-blocks/page-video-section': PageVideoSection,
    'team-bumble-ui-blocks/page-values-section': PageValuesSection,
    'team-bumble-ui-blocks/page-quotes-carousel-section': PageQuotesCarouselSectionBlock,
    'team-bumble-ui-blocks/success-stories': SuccessStories,
    'team-bumble-ui-blocks/interview-section': InterviewSection,
};

export default defaultBlockMap;
