import React from 'react';
import classNames from 'classnames';
import MediaBox, { MediaBoxAspect } from 'components/media-box/media-box';

type ColumnMediaCardProps = {
    src: string;
    title?: React.ReactNode;
    aspectRatio?: number;
    hasShadow?: boolean;
};

const ColumnMediaCard: React.FC<ColumnMediaCardProps> = (props) => {
    const { title, aspectRatio = 1, src, hasShadow } = props;
    const classnames = {
        block: classNames({
            'column-media-card': true,
            'has-shadow': hasShadow,
        }),
    };

    return (
        <div className={classnames.block} style={{ ['--aspect-ratio' as string]: aspectRatio }}>
            <MediaBox aspect={MediaBoxAspect.FREE} src={src} />

            {title ? (
                <div className="column-media-card__overlay">
                    <div className="column-media-card__title">{title}</div>
                </div>
            ) : null}
        </div>
    );
};

export default ColumnMediaCard;
