import React from 'react';
import { BlockProps } from '../index.types';
import PageTwoTextImageSection, {
    PageTwoTextsImageSectionProps as PageTwoTextImageSectionProps,
} from 'components/page-section-two-columns/page-two-texts-image-section';

export default function PageTwoTextImageSectionBlock({
    block,
    ...props
}: BlockProps & PageTwoTextImageSectionProps) {
    return (
        <PageTwoTextImageSection
            isSecondary={props.isSecondary}
            brand={props.brand}
            title={props.title}
            text={props.text}
            extraText={props.extraText}
            imageAspectRatio={props.imageAspectRatio}
            imagePosition={props.imagePosition || 'left'}
            imageSrc={props.imageSrc}
            secondTitle={props.secondTitle}
            secondText={props.secondText}
            secondExtraText={props.secondExtraText}
        />
    );
}
