import React from 'react';
import { BlockProps } from '../index.types';
import BenefitsOfficeCardsWrapper from 'components/benefits-office-cards/benefits-office-cards-wrapper';
import BenefitsOfficeCardsItem from 'components/benefits-office-cards/benefits-office-cards-item';
import Link from 'components/link/link';
import MediaBox, { MediaBoxAspect } from 'components/media-box/media-box';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import PageSection from 'components/page/page-section';

export default function BenefitsOfficeCardsWrapperBlock({ block }: BlockProps) {
    const isBreakpoint = useMediaQuery(MediaQueryWidth['desktop-md']);

    /**
     * temporary fix for Demo
     */
    const innerBlocks = block.innerBlocks?.map((innerBlock, index) => {
        if (!innerBlock) {
            return undefined;
        }

        if (innerBlock.name === 'team-bumble-ui-blocks/office-cards-item') {
            const src = innerBlock.attributes?.filter(
                (attribute) => attribute?.name === 'mediaURL'
            )[0]?.value;
            const url = innerBlock.attributes?.filter((attribute) => attribute?.name === 'url')[0]
                ?.value;
            const city = innerBlock.attributes?.filter(
                (attribute) => attribute?.name === 'title'
            )[0]?.value;
            const country = innerBlock.attributes?.filter(
                (attribute) => attribute?.name === 'subTitle'
            )[0]?.value;
            const subTitle = innerBlock.attributes?.filter(
                (attribute) => attribute?.name === 'caption'
            )[0]?.value;
            const openInNewWindow = innerBlock.attributes?.filter(
                (attribute) => attribute?.name === 'openInNewWindow'
            )[0]?.value;
            const imageBlock = src ? (
                <MediaBox
                    aspect={isBreakpoint ? MediaBoxAspect.IPHONE6 : MediaBoxAspect.BENEFITS}
                    src={src}
                />
            ) : null;

            const linkComponent = ({
                children,
            }: {
                children: React.ReactNode | React.ReactNode[];
            }) =>
                url ? (
                    <Link
                        href={url}
                        className="benefits-office-card"
                        target={openInNewWindow ? '_blank' : '_self'}
                    >
                        {children}
                    </Link>
                ) : (
                    <>{children}</>
                );

            return (
                <BenefitsOfficeCardsItem
                    // @ts-ignore
                    LinkComponent={linkComponent}
                    // @ts-ignore
                    url={url}
                    // @ts-ignore
                    city={city}
                    // @ts-ignore
                    country={country}
                    // @ts-ignore
                    subtitle={subTitle}
                    src={imageBlock}
                    key={`${innerBlock.name}-${index}`}
                />
            );
        }

        return undefined;
    });
    return (
        <PageSection theme={PageSection.Theme.NONE} isFullWidth={true} splashScreen={true}>
            {/* @ts-ignore */}
            <BenefitsOfficeCardsWrapper>{innerBlocks}</BenefitsOfficeCardsWrapper>
        </PageSection>
    );
}
