import * as React from 'react';
import AppsLinks from 'components/apps-links/apps-links';
import PageSuccessStories, {
    SuccessStory,
} from 'components/page-success-stories/page-success-stories-dynamic';
import HeroBlock from 'components/hero-block/hero-block';
import CtaBox, { CtaBoxAlign } from 'components/cta-box/cta-box';
import CtaBoxTitle, { CtaBoxTitleAlign } from 'components/cta-box/cta-box-title';
import CtaBoxMedia from 'components/cta-box/cta-box-media';
import Header1 from 'components/typography/header-1';
import Header2 from 'components/typography/header-2';
import useMediaQuery from 'lib/use-media-query';
import { animated, useTrail, SpringValue } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';

export type PageHeroBlockPropsType = {
    title?: string;
    stories?: SuccessStory[];
};

type AnimatedElementType = {
    y?: SpringValue<number>;
    elementRef?: React.RefObject<HTMLDivElement>;
    children?: React.ReactNode;
};

const AnimatedElement: React.FC<React.PropsWithChildren<AnimatedElementType>> = ({
    // eslint-disable-next-line id-length
    y,
    children,
    elementRef,
    ...otherProps
}) => {
    return (
        <animated.div
            style={{
                ...otherProps,
                transform: y?.to((value: number) => `translate3d(0, ${value}px, 0)`),
            }}
            className="animations-collection"
            ref={elementRef}
        >
            {children}
        </animated.div>
    );
};

const PageHeroBlock = (props: PageHeroBlockPropsType) => {
    const { title, stories } = props;
    const isBreakpoint = useMediaQuery(640);
    const { ref, inView } = useInView({
        /* Optional settings */
        threshold: 0,
        delay: 600,
    });
    const elementRef = React.useRef<HTMLDivElement>(null);
    const onRestHandler = () => {
        if (elementRef.current) {
            elementRef.current
                .querySelectorAll('.has-circle, .has-underline')
                .forEach((item: Element, index: number) => {
                    item.classList.toggle('animated', inView);
                    item.classList.toggle(`animated--order-${index}`, inView);
                });

            elementRef.current.querySelectorAll('.has-circle').forEach((item: Element) => {
                if (item.querySelectorAll('.inner-circle').length === 0) {
                    const value = item.innerHTML;

                    item.innerHTML = `<svg class="inner-circle" width="435" height="64" viewBox="0 0 435 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M434 32C434 33.7172 432.812 35.6137 429.997 37.6372C427.214 39.6385 423.045 41.6102 417.579 43.5114C406.661 47.3092 390.807 50.746 371.15 53.6381C331.852 59.4199 277.528 63 217.5 63C157.472 63 103.148 59.4199 63.8498 53.6381C44.1931 50.746 28.3391 47.3092 17.4208 43.5114C11.9553 41.6102 7.78615 39.6385 5.00259 37.6372C2.18822 35.6137 1 33.7172 1 32C1 30.2828 2.18822 28.3863 5.00259 26.3628C7.78615 24.3615 11.9553 22.3898 17.4208 20.4886C28.3391 16.6908 44.1931 13.254 63.8498 10.3619C103.148 4.58012 157.472 1 217.5 1C277.528 1 331.852 4.58012 371.15 10.3619C390.807 13.254 406.661 16.6908 417.579 20.4886C423.045 22.3898 427.214 24.3615 429.997 26.3628C432.812 28.3863 434 30.2828 434 32Z" stroke="#FFC629" stroke-width="2"/></svg>${value}`;
                }
            });
        }
    };

    const trail = useTrail(2, {
        from: { opacity: 0, y: 20 },
        to: { opacity: inView ? 1 : 0, y: inView ? 20 : 10 },
        onRest: onRestHandler,
        config: { mass: 5, tension: 2000, friction: 200 },
    });

    return (
        <>
            <HeroBlock
                isFullScreen={true}
                hasInnerContainer={true}
                isStickyBlock={true}
                hasStickyOnMobile={true}
            >
                <CtaBox align={CtaBoxAlign.CENTERED}>
                    <CtaBoxTitle align={CtaBoxTitleAlign.CENTERED}>
                        {isBreakpoint ? (
                            <AnimatedElement elementRef={elementRef} {...trail[0]}>
                                <Header2
                                    // eslint-disable-next-line react/forbid-component-props
                                    style={{ maxWidth: 1300, margin: '0 auto' }}
                                    html={title}
                                />
                            </AnimatedElement>
                        ) : (
                            <AnimatedElement elementRef={elementRef} {...trail[0]}>
                                <Header1
                                    // eslint-disable-next-line react/forbid-component-props
                                    style={{ maxWidth: 1300, margin: '0 auto' }}
                                    html={title}
                                />
                            </AnimatedElement>
                        )}
                    </CtaBoxTitle>

                    <>
                        <i ref={ref}></i>

                        <AnimatedElement {...trail[1]}>
                            <CtaBoxMedia marginTop={80}>
                                <AppsLinks isSpacious={!isBreakpoint} noLinks={true} />
                            </CtaBoxMedia>
                        </AnimatedElement>
                    </>
                </CtaBox>
            </HeroBlock>

            {stories ? <PageSuccessStories stories={stories} /> : null}
        </>
    );
};

export default PageHeroBlock;
