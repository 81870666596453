import React from 'react';

type CtaBoxCaptionProps = {
    marginTop?: number;
    children?: React.ReactChild | React.ReactChild[];
};
const CtaBoxCaption: React.FC<CtaBoxCaptionProps> = ({ children, marginTop }) => {
    return (
        <div className="cta-box__caption" style={{ marginTop }}>
            {children}
        </div>
    );
};

export default CtaBoxCaption;
