import React from 'react';
import Columns from 'components/columns/columns';
import ColumnsItem, { ColumnsSize } from 'components/columns/columns-item';
import MediaBox, { MediaBoxAspect } from 'components/media-box/media-box';
import CtaBox, { CtaBoxAlign } from 'components/cta-box/cta-box';
import CtaBoxCaption from 'components/cta-box/cta-box-caption';
import CtaBoxMedia, { CtaBoxMediaAlign } from 'components/cta-box/cta-box-media';
import CtaBoxSubtitle from 'components/cta-box/cta-box-subtitle';
import { Header1, P1 } from 'components/typography';
import PageSection from 'components/page/page-section';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
// yarn prod Cannot use import statement outside a module
// import { Grid } from '@bumble/team-bumble-ui-components';
import Grid from 'components/grid/grid';

export type BenefitSectionEntry = {
    image: string;
    title: React.ReactNode;
    titleHtml?: string;
    text: React.ReactNode;
    textHtml?: string;
    marginLeft?: number;
};

type BenefitSectionType = {
    title?: React.ReactNode;
    titleHtml?: string;
    list: BenefitSectionEntry[];
    isPrimary?: boolean;
};

const BenefitSection: React.FC<BenefitSectionType> = (props) => {
    const { title, titleHtml, list, isPrimary = true } = props;

    const isBreakpoint = useMediaQuery(MediaQueryWidth['tablet-md']);
    const headerBlock = (
        <header className="benefit-section__header">
            {titleHtml ? <Header1 html={titleHtml} /> : <Header1>{title}</Header1>}
        </header>
    );

    return (
        <React.Fragment>
            {isPrimary ? (
                <PageSection splashScreen={true} extraClass="benefit-section page__section--padded">
                    {headerBlock}
                    <Grid extraClass="benefit-section__grid" threeColumns={true}>
                        {list.map(
                            (
                                {
                                    image,
                                    title: listTitle,
                                    titleHtml: listTitleHtml,
                                    text,
                                    textHtml,
                                    marginLeft,
                                },
                                idx
                            ) => (
                                <CtaBox
                                    align={isBreakpoint ? undefined : CtaBoxAlign.CENTERED}
                                    key={`cta-${idx}`}
                                >
                                    <CtaBoxMedia
                                        align={isBreakpoint ? undefined : CtaBoxMediaAlign.CENTERED}
                                        marginTop={isBreakpoint ? 10 : 20}
                                    >
                                        <div
                                            className="benefit-section__entry-image"
                                            style={{
                                                marginLeft,
                                            }}
                                        >
                                            <MediaBox src={image} aspect={MediaBoxAspect.SQUARE} />
                                        </div>
                                    </CtaBoxMedia>
                                    <CtaBoxCaption marginTop={40}>
                                        {listTitleHtml ? (
                                            <P1
                                                extraClass="benefit-section__entry-title"
                                                html={listTitleHtml}
                                            />
                                        ) : (
                                            <P1 extraClass="benefit-section__entry-title">
                                                {listTitle}
                                            </P1>
                                        )}
                                    </CtaBoxCaption>
                                    <CtaBoxSubtitle>
                                        {textHtml ? (
                                            <P1
                                                extraClass="benefit-section__entry-text"
                                                html={textHtml}
                                            />
                                        ) : (
                                            <P1 extraClass="benefit-section__entry-text">{text}</P1>
                                        )}
                                    </CtaBoxSubtitle>
                                </CtaBox>
                            )
                        )}
                    </Grid>
                </PageSection>
            ) : null}

            {!isPrimary ? (
                <PageSection
                    splashScreen={true}
                    extraClass="benefit-section page__section--padded page__section--secondary"
                >
                    <Columns vAlignTop={true} hasMobileLayout={true}>
                        <ColumnsItem size={ColumnsSize['1/1']}>{headerBlock}</ColumnsItem>

                        <ColumnsItem size={ColumnsSize['1/1']}>
                            {list.map(
                                (
                                    {
                                        image,
                                        title: listTitle,
                                        titleHtml: listTitleHtml,
                                        text,
                                        textHtml,
                                        marginLeft,
                                    },
                                    idx
                                ) => {
                                    return (
                                        <CtaBox key={`cta-${idx}`}>
                                            <CtaBoxMedia marginTop={isBreakpoint ? 10 : 0}>
                                                <div
                                                    className="benefit-section__entry-image"
                                                    style={{
                                                        marginLeft,
                                                    }}
                                                >
                                                    <MediaBox
                                                        src={image}
                                                        aspect={MediaBoxAspect.SQUARE}
                                                    />
                                                </div>
                                            </CtaBoxMedia>
                                            <CtaBoxCaption marginTop={0}>
                                                {listTitleHtml ? (
                                                    <P1
                                                        extraClass="benefit-section__entry-title"
                                                        html={listTitleHtml}
                                                    />
                                                ) : (
                                                    <P1 extraClass="benefit-section__entry-title">
                                                        {listTitle}
                                                    </P1>
                                                )}
                                            </CtaBoxCaption>
                                            <CtaBoxSubtitle>
                                                {textHtml ? (
                                                    <P1
                                                        extraClass="benefit-section__entry-text"
                                                        html={textHtml}
                                                    />
                                                ) : (
                                                    <P1 extraClass="benefit-section__entry-text">
                                                        {text}
                                                    </P1>
                                                )}
                                            </CtaBoxSubtitle>
                                        </CtaBox>
                                    );
                                }
                            )}
                        </ColumnsItem>
                    </Columns>
                </PageSection>
            ) : null}
        </React.Fragment>
    );
};

export default BenefitSection;
