import React from 'react';
import * as CSS from 'csstype';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';

type ColumnMediaCardWrapperProps = {
    mediaquery?: MediaQueryWidth;
    maxWidth?: CSS.Properties['maxWidth'];
    maxHeight?: CSS.Properties['maxHeight'];
    marginBottom?: CSS.Properties['marginBottom'];
    children: React.ReactNode;
};

const ColumnMediaCardWrapper: React.FC<React.PropsWithChildren<ColumnMediaCardWrapperProps>> = (
    props
) => {
    const { children, mediaquery = 640, maxHeight, maxWidth, marginBottom } = props;

    const isBreakpoint = useMediaQuery(mediaquery);
    const maxWidthDefault = isBreakpoint ? 'none' : 585;

    const imageStyle = {
        maxWidth: maxWidth || maxWidthDefault,
        width: '100%',
        maxHeight,
        margin: '0 auto',
        marginBottom,
    };

    return <div style={imageStyle}>{children}</div>;
};

export default ColumnMediaCardWrapper;
