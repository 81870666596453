import React from 'react';
import cx from 'classnames';

type Props = {
    children?: React.ReactNode;
    threeColumns?: boolean;
    reverse?: boolean;
    extraClass?: string;
    jsClass?: string;
};

function Grid(props: Props) {
    const { children, threeColumns, reverse, extraClass, jsClass } = props;

    const className = cx({
        grid: true,
        'grid--triple ': threeColumns,
        'grid--reverse': reverse,
        [extraClass as string]: extraClass,
        [jsClass as string]: jsClass,
    });

    return (
        <ul className={className}>
            {React.Children.map(children, (child) => (
                <li className="grid__cell">{child}</li>
            ))}
        </ul>
    );
}

export default Grid;
