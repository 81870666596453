import React from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import VideoCarousel from 'components/video-carousel/video-carousel-dynamic';
import ModalContext from 'components/modal-context';
import { ModalColorScheme } from 'components/modal/modal.config';
import Slider, { ArrowsLayout } from 'components/slider/slider';
import Loader, { LoaderSize, LoaderColor } from 'components/loader/loader';

const opts = {
    playerVars: {
        autoplay: 0,
        color: 'white',
        modestbranding: 1,
        rel: 0,
    },
};

type Video = {
    id: string;
    url: string;
    imageSrc: string;
};

type PageVideoCarouselWithModalPropsType = {
    videoList: Video[];
};
const PageVideoCarouselWithModal: React.FC<PageVideoCarouselWithModalPropsType> = ({
    videoList,
}) => {
    const videoElements: any = [];
    const sliderWrapperRef = React.useRef<HTMLDivElement>(null);
    const { setModalOpen, setModalContent, setModalColor, setScrollY } =
        React.useContext(ModalContext);

    const playBackHandler: YouTubeProps['onEnd'] = () => {
        if (setModalOpen) {
            setModalOpen(false);
        }
    };

    const onCLickHandler = (valueId: number) => (_event?: React.MouseEvent<HTMLDivElement>) => {
        _event?.stopPropagation();

        if (setScrollY) {
            setScrollY(global.document.body.scrollTop || global.document.documentElement.scrollTop);
        }
        if (setModalOpen) {
            setModalOpen(true);
        }
        if (setModalContent) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            setModalContent(modalContent(valueId));
        }
        if (setModalColor) {
            setModalColor(ModalColorScheme.Secondary);
        }
    };

    const videos = videoList.map((video, index) => {
        return {
            ...video,
            onClick: onCLickHandler(index),
        };
    });

    const pauseVideos = () => {
        // @ts-ignore
        videoElements.forEach((video) => video.pauseVideo());
        videoElements.pop();
    };
    const modalContent = (videoIndex: number) => (
        <Slider
            index={videoIndex}
            loop={true}
            spaceBetween={120}
            navigation={true}
            arrowColorSecondary={true}
            arrowsLayout={ArrowsLayout.Relative}
            onPrevClickActionHandler={pauseVideos}
            onNextClickActionHandler={pauseVideos}
        >
            {videos.map((item, index) => {
                return (
                    <div
                        key={`${item.id}${index}`}
                        style={{
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                paddingTop: '56.35%',
                                minWidth: '50vw',
                            }}
                        />
                        <Loader size={LoaderSize.LARGE} color={LoaderColor.PRIMARY} />

                        <div className="media-box-youtube">
                            <YouTube
                                videoId={item.id}
                                // eslint-disable-next-line react/forbid-component-props
                                className="media-box-youtube__frame"
                                opts={opts as YouTubeProps['opts']}
                                onPlay={(event) => videoElements.push(event.target)}
                                onEnd={playBackHandler}
                                // @todo: consider smth like this later
                                // onPause={() => setModalOpen && setModalOpen(false)}
                            />
                        </div>
                    </div>
                );
            })}
        </Slider>
    );

    return (
        <div className="page-video-carousel-with-modal">
            <div className="page-video-carousel-with-modal__video-list" ref={sliderWrapperRef}>
                <VideoCarousel videoList={videos} />
            </div>
        </div>
    );
};

export default PageVideoCarouselWithModal;
