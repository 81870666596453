import React from 'react';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import PageSectionTwoColumns, {
    PageSectionColumnSize,
} from 'components/page-section-two-columns/page-section-two-columns';
import TextSection, { TextSectionTitleStyle } from 'components/text-section/text-section';
import PageSection from 'components/page/page-section';
import PageVideoCarouselWithModal from 'components/page-video-carousel-with-modal/page-video-carousel-with-modal-dynamic';

export type VideoListItem = {
    id: string;
    url: string;
    imageSrc: string;
};

export type VideoList = VideoListItem[];

export type PageVideoSectionProps = {
    title: string;
    text: string;
    videoPosition?: 'left' | 'right';
    mediaquery?: MediaQueryWidth;
    brand?: 'bumble' | 'badoo';
    isSecondary?: boolean;
    videos: VideoList;
};

const PageVideoSection: React.FC<PageVideoSectionProps> = (props) => {
    const {
        title,
        text,
        mediaquery = 640,
        brand = 'bumble',
        videoPosition = 'left',
        videos,
        isSecondary,
    } = props;

    const isBreakpoint = useMediaQuery(mediaquery);

    const textBlock = (
        <TextSection
            titleStyle={TextSectionTitleStyle.H2}
            titleCenter={isBreakpoint}
            title={text}
        />
    );

    const videoBlock = <PageVideoCarouselWithModal videoList={videos} />;

    const sectionClass = `page__section--padded ${brand} ${
        isSecondary ? 'page__section--secondary' : null
    }`;

    const videoSection = isBreakpoint ? (
        <>
            <PageSectionTwoColumns
                vAlignTop={true}
                title={title}
                titleCenter={isBreakpoint}
                equal={true}
                left={textBlock}
                extraClass={sectionClass}
            />
            <PageSection
                inner={false}
                splashScreen={false}
                extraClass={`page__section--padded page__section--no-margin-from-top ${brand} ${
                    isSecondary ? 'page__section--secondary' : null
                }`}
            >
                {videoBlock}
            </PageSection>
        </>
    ) : (
        <PageSectionTwoColumns
            vAlignTop={true}
            title={title}
            titleCenter={isBreakpoint}
            left={videoPosition === 'right' ? textBlock : videoBlock}
            leftColumnsSize={PageSectionColumnSize['1/3']}
            right={videoPosition === 'right' ? videoBlock : textBlock}
            rightColumnsSize={PageSectionColumnSize['2/3']}
            rightLimitedWidth={true}
            extraClass={sectionClass}
        />
    );

    return videoSection;
};

export default PageVideoSection;
