import React from 'react';

const FooterGridMenu: React.FC<React.PropsWithChildren> = (props) => {
    return (
        <ul className="footer-grid-menu">
            {React.Children.map(props.children, (child) => (
                <li className="footer-grid-menu__item">{child}</li>
            ))}
        </ul>
    );
};

export default FooterGridMenu;
