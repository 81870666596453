import React from 'react';
import Caption from 'components/typography/caption';

const MobileCaption: React.FC<React.PropsWithChildren> = ({ children }) => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginBottom: 46,
            textAlign: 'center',
        }}
    >
        <Caption> {children}</Caption>
    </div>
);

export default MobileCaption;
