import React from 'react';
import Image from 'lib/image-container';
import cx from 'classnames';
import Caption from 'components/typography/caption';
import Header2 from 'components/typography/header-2';

export type ValueProps = {
    title: React.ReactNode;
    imageSrc?: string;
    onClick: (event: React.MouseEvent<HTMLLIElement>) => void;
};

type ValuesListProps = {
    title?: React.ReactNode;
    values: ValueProps[];
    isSpacious?: boolean;
    children?: React.ReactNode;
};

const ValuesList: React.FunctionComponent<ValuesListProps> = ({
    values,
    title,
    isSpacious,
    children,
}) => {
    const className = cx({
        'values-list': true,
        'values-list--spacious': isSpacious,
    });

    return (
        <div className={className}>
            {title ? (
                <div className="values-list__title">
                    <Caption>{title}</Caption>
                </div>
            ) : null}
            <ul className="values-list__items">
                {values.map((value, index) => {
                    return (
                        <li className="values-list__item" key={index} onClick={value.onClick}>
                            <div className="values-list__item-symbol">
                                {value.imageSrc ? (
                                    <Image src={value.imageSrc} alt="" fill={true} />
                                ) : null}
                            </div>
                            <div className="values-list__item-title">
                                <Header2>{value.title}</Header2>
                            </div>
                        </li>
                    );
                })}

                {children}
            </ul>
        </div>
    );
};

export default ValuesList;
