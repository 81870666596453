import React from 'react';
import { BlockProps } from '../index.types';
import PageSection from 'components/page/page-section';
import PostContent from '../PostContent/PostContent';

type PageSectionType = {
    block: BlockProps['block'];
    hasFullWidth?: 'true' | 'false';
    hasExtraHorizontalPadding?: 'true' | 'false';
    hasOnlyTopPadding?: 'true' | 'false';
    isPrimary?: 'true' | 'false';
    hasFullWidthOnMobile?: 'true' | 'false';
    hasInnerWrapper?: 'true' | 'false';
};

export default function PageSectionBlock({ block, ...props }: PageSectionType) {
    // @TODO: should we divide Post content and mapping of blocks?
    return block.innerBlocks ? (
        <PageSection
            theme={PageSection.Theme.NONE}
            isFullWidth={props.hasFullWidth === 'true'}
            hasPadding={props.hasExtraHorizontalPadding === 'true'}
            hasOnlyTopPadding={props.hasOnlyTopPadding === 'true'}
            isPrimary={props.isPrimary === 'true'}
            hasFullWidthOnMobile={props.hasFullWidthOnMobile === 'true'}
            inner={props.hasInnerWrapper === 'true'}
        >
            <PostContent blocks={block.innerBlocks} />
        </PageSection>
    ) : null;
}
