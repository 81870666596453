/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import { useSpring, animated, Spring } from '@react-spring/web';
import { InView } from 'react-intersection-observer';

type AnimationType = {
    children?: React.ReactNode;
    y?: number;
    opacity?: number;
    delay?: number;
    onRest?: () => void;
};

export const AnimationAppear: React.FC<AnimationType> = ({
    children,
    onRest,
    y: animationY,
    delay,
}) => {
    const startYposition = animationY || 28;
    const config = useSpring({
        delay,
        opacity: 1,
        transform: 'translate3d(0,0,0)',
        from: { opacity: 0, transform: `translate3d(0,${startYposition}px, 0)` },
        onRest,
    });
    return (
        <>
            {React.Children.toArray(children)
                .filter(Boolean)
                .map((item, index) => (
                    <animated.div style={config} key={item.toString() + index}>
                        {item}
                    </animated.div>
                ))}
        </>
    );
};

export const AnimationFadeIn: React.FC<AnimationType> = ({
    children,
    onRest,
    opacity = 0,
    delay,
}) => {
    const config = useSpring({
        delay,
        opacity: 1,
        transform: 'translate3d(0,0,0)',
        from: { opacity },
        onRest,
    });
    return (
        <>
            {React.Children.toArray(children)
                .filter(Boolean)
                .map((item, index) => (
                    <animated.div style={config} key={item.toString() + index}>
                        {item}
                    </animated.div>
                ))}
        </>
    );
};

export const AnimationObservableAppearance: React.FC<AnimationType> = ({
    children,
    onRest,
    delay = 100,
}) => {
    const elementRef = React.useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = React.useState(false);
    const onRestHandler = onRest
        ? () => onRest
        : () => {
              if (elementRef.current) {
                  elementRef.current
                      .querySelectorAll('.has-circle, .has-underline')
                      .forEach((item: Element, index: number) => {
                          item.classList.toggle('animated', isVisible);
                          item.classList.toggle(`animated--order-${index}`, isVisible);
                      });

                  elementRef.current.querySelectorAll('.has-circle').forEach((item: Element) => {
                      if (item.querySelectorAll('.inner-circle').length === 0) {
                          const value = item.innerHTML;

                          item.innerHTML = `<svg class="inner-circle" width="435" height="64" viewBox="0 0 435 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M434 32C434 33.7172 432.812 35.6137 429.997 37.6372C427.214 39.6385 423.045 41.6102 417.579 43.5114C406.661 47.3092 390.807 50.746 371.15 53.6381C331.852 59.4199 277.528 63 217.5 63C157.472 63 103.148 59.4199 63.8498 53.6381C44.1931 50.746 28.3391 47.3092 17.4208 43.5114C11.9553 41.6102 7.78615 39.6385 5.00259 37.6372C2.18822 35.6137 1 33.7172 1 32C1 30.2828 2.18822 28.3863 5.00259 26.3628C7.78615 24.3615 11.9553 22.3898 17.4208 20.4886C28.3391 16.6908 44.1931 13.254 63.8498 10.3619C103.148 4.58012 157.472 1 217.5 1C277.528 1 331.852 4.58012 371.15 10.3619C390.807 13.254 406.661 16.6908 417.579 20.4886C423.045 22.3898 427.214 24.3615 429.997 26.3628C432.812 28.3863 434 30.2828 434 32Z" stroke="#FFC629" stroke-width="2"/></svg>${value}`;
                      }
                  });
              }
          };

    return (
        <>
            {React.Children.toArray(children)
                .filter(Boolean)
                .map((item, index) => (
                    <InView
                        as="div"
                        onChange={(inView) => setIsVisible(inView)}
                        delay={delay}
                        threshold={0.6}
                        key={item.toString() + index}
                    >
                        <Spring
                            delay={100}
                            to={{
                                opacity: isVisible ? 1 : 0,
                                transform: isVisible ? 'translateY(0)' : 'translateY(200px)',
                            }}
                            onRest={onRestHandler}
                        >
                            {({ opacity, transform }) => (
                                <animated.div
                                    className="animations-collection"
                                    style={{
                                        transform,
                                        opacity,
                                    }}
                                    ref={elementRef}
                                >
                                    {item}
                                </animated.div>
                            )}
                        </Spring>
                    </InView>
                ))}
        </>
    );
};
