import React from 'react';
import cx from 'classnames';

export enum CtaBoxTitleAlign {
    CENTERED = 'CENTERED',
}

const mapAlignToClasses = {
    [CtaBoxTitleAlign.CENTERED]: 'cta-box__title--centered',
};

type CtaBoxTitleProps = {
    align?: CtaBoxTitleAlign;
    marginTop?: number;
    children?: React.ReactChild | React.ReactChild[];
};
const CtaBoxTitle: React.FC<CtaBoxTitleProps> = (props) => {
    const { align, children, marginTop } = props;
    const classname = cx(
        {
            'cta-box__title': true,
        },
        typeof align !== 'undefined' && mapAlignToClasses[align]
    );
    const style = {
        marginTop,
    };
    return (
        <div className={classname} style={style}>
            {children}
        </div>
    );
};

export default CtaBoxTitle;
