import React from 'react';
import cx from 'classnames';
import Link from 'components/link/link';
import GenericLogoBadoo from 'public/assets/generic-logo-badoo.svg';
import GenericLogoBumble from 'public/assets/generic-logo-bumble.svg';

const defaultItems: ItemProps[] = [
    {
        url: 'https://bumble.com/',
        labelText: 'Bumble',
        icon: <GenericLogoBumble />,
        color: 'bumble',
    },
    {
        url: 'https://badoo.com/',
        labelText: 'Badoo',
        icon: <GenericLogoBadoo />,
        color: 'badoo',
    },
];

type ItemProps = {
    url: string;
    icon: React.ReactNode;
    labelText: string;
    color?: 'badoo' | 'bumble';
};

export type AppsLinksProps = {
    items?: ItemProps[];
    color?: 'primary' | 'base';
    variant?: 'default' | 'compact';
    isSpacious?: boolean; // sizes toggle between mobile and desktop
    noLinks?: boolean;
};

function AppsLinks(this: any, props: AppsLinksProps) {
    const {
        items = defaultItems,
        color = 'primary',
        isSpacious,
        variant = 'default',
        noLinks,
    } = props;

    const className = cx({
        'apps-links': true,
        [`apps-links--${variant}`]: true,
        'apps-links--spacious': isSpacious,
    });

    return (
        <ul className={className}>
            {items &&
                items.map((itemProps: ItemProps, index: any) => {
                    const linkClassName = cx({
                        'apps-links__link': true,
                        [`apps-links__link--color-${color || itemProps.color}`]: true,
                    });

                    return (
                        <li className="apps-links__item" key={`apps-links-item-${index}`}>
                            {noLinks ? (
                                <div className={linkClassName}>{itemProps.icon}</div>
                            ) : (
                                <Link
                                    aria-label={itemProps.labelText}
                                    className={linkClassName}
                                    href={itemProps.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {itemProps.icon}
                                </Link>
                            )}
                        </li>
                    );
                })}
        </ul>
    );
}

export default AppsLinks;
