import React from 'react';
import QuotesCarouselCard, {
    QuotesCarouselCardProps,
} from 'components/quotes-carousel/quotes-carousel-card-dynamic';
import Slider from 'components/slider/slider-wth-navigation';
import Slide from 'components/slider/slide';

type QuotesCarouselProps = {
    cards: QuotesCarouselCardProps[];
    isSpacious?: boolean;
};

const QuotesCarousel: React.FunctionComponent<QuotesCarouselProps> = ({ cards, isSpacious }) => {
    return (
        <div className="quotes-carousel">
            {/* @ts-ignore */}
            <Slider>
                <React.Fragment>
                    {cards.map((item, index) => (
                        <Slide key={`${item.name}${index}`}>
                            <QuotesCarouselCard
                                key={index}
                                text={item.text}
                                name={item.name}
                                position={item.position}
                                imageSrc={item.imageSrc}
                                alt={item.alt}
                                isSpacious={isSpacious}
                            />
                        </Slide>
                    ))}
                </React.Fragment>
            </Slider>
        </div>
    );
};

export default QuotesCarousel;
