import React from 'react';
import Image, { ImageProps } from 'next/image';
import { baseUrl } from 'lib/base-url';

const ImageContainer = (props: ImageProps) => {
    const src = props.src as string;
    return (
        <Image
            {...props}
            src={src.replace(
                /http:\/\/cdn-internal:8081(.+?)\.(svg)/g,
                `${baseUrl}/team/assets$1.$2`
            )}
            width={props.width}
            height={props.height}
            fill={!props.width && !props.height}
            // unoptimized={src.endsWith('svg')}
        />
    );
};

export default ImageContainer;
