import React from 'react';
import HeroBlock from 'components/hero-block/hero-block';
import HeroImage from 'components/hero-image/hero-image';

import useMediaQuery from 'lib/use-media-query';
import PageSection from 'components/page/page-section';
import CtaBox, { CtaBoxAlign } from 'components/cta-box/cta-box';
import CtaBoxTitle, { CtaBoxTitleAlign } from 'components/cta-box/cta-box-title';
import Header1 from 'components/typography/header-1';
import Header3 from 'components/typography/header-3';
import Header4 from 'components/typography/header-4';
import P1 from 'components/typography/p-1';
import { Columns, ColumnsItem } from 'components/columns';
import { AnimationObservableAppearance } from 'components/animations/animations';

const pageSectionextraClass =
    'page__section--secondary page__section--padded text-extra-space has-inlined-text';

export type PageSectionCeoMessageProps = {
    title?: string;
    name: string;
    text: string;
    imageSrc: string;
    imageMobileSrc: string;
};

const PageSectionCeoMessage: React.FC<PageSectionCeoMessageProps> = ({
    title,
    imageSrc,
    imageMobileSrc,
    name,
    text,
}) => {
    const isBreakpoint = useMediaQuery();
    const stikyElementRef = React.useRef<HTMLDivElement>(null);

    return (
        <>
            <HeroBlock
                isFluid={true}
                title={
                    title ? (
                        <AnimationObservableAppearance>
                            <CtaBox align={isBreakpoint ? undefined : CtaBoxAlign.CENTERED}>
                                <CtaBoxTitle align={CtaBoxTitleAlign.CENTERED}>
                                    <Header1
                                        color="white"
                                        // eslint-disable-next-line react/forbid-component-props
                                        style={{ maxWidth: 680, margin: '0 auto' }}
                                        html={title}
                                    />
                                </CtaBoxTitle>
                            </CtaBox>
                        </AnimationObservableAppearance>
                    ) : null
                }
            >
                {imageMobileSrc && imageSrc ? (
                    <HeroImage
                        src={isBreakpoint ? imageMobileSrc : imageSrc}
                        aspectRatio={isBreakpoint ? 375 / 454 : 1440 / 700}
                    />
                ) : null}

                <PageSection splashScreen={true} extraClass={pageSectionextraClass}>
                    <Columns vAlignTop={true} hasMobileLayout={true}>
                        <ColumnsItem
                            size={ColumnsItem.size['1/1']}
                            isSticky={true}
                            addStickyHeaderGap={true}
                            reference={stikyElementRef}
                        >
                            <Header3 html={name} />
                        </ColumnsItem>
                        <ColumnsItem size={ColumnsItem.size['1/1']}>
                            {isBreakpoint ? <P1 html={text} /> : <Header4 html={text} />}
                        </ColumnsItem>
                    </Columns>
                </PageSection>
            </HeroBlock>
        </>
    );
};

export default PageSectionCeoMessage;
