import React from 'react';
import { BlockProps } from '../index.types';
import PageSectionIntroOneColumn, {
    PageSectionIntroOneColumnPropsType,
} from 'components/page-section-intro/page-section-intro-one-column';
import { decodePossible } from 'lib/decode';

type PageSectionIntroOneColumnBlockType = {
    block: BlockProps;
} & PageSectionIntroOneColumnPropsType;

export default function PageSectionIntroOneColumnBlock({
    block,
    ...props
}: PageSectionIntroOneColumnBlockType) {
    return (
        <PageSectionIntroOneColumn
            caption={decodePossible(props.caption)}
            title={decodePossible(props.title)}
            text={decodePossible(props.text)}
            width={props.width ? `${props.width}px` : '100%'}
            columnWidth={props.columnWidth ? `${props.columnWidth}px` : '100%'}
            vAlignTop={props.vAlignTop}
            extraClass={props.extraClass}
        />
    );
}
