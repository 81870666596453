import React from 'react';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';

type ButtonWrapperProps = {
    mediaquery?: MediaQueryWidth;
    children: React.ReactNode;
};

const ButtonWrapper: React.FC<React.PropsWithChildren<ButtonWrapperProps>> = (props) => {
    const { children, mediaquery = 640 } = props;

    const isBreakpoint = useMediaQuery(mediaquery);

    const ctaStyle = {
        margin: '25px auto 0',
        width: isBreakpoint ? 'fit-content' : '100%',
    };

    return <div style={ctaStyle}>{children}</div>;
};

export default ButtonWrapper;
