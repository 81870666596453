import React from 'react';
import HeroBlock from 'components/hero-block/hero-block';
import Header1 from 'components/typography/header-1';
import { Columns, ColumnsItem } from 'components/columns';
import MediaCard from 'components/media-card/media-card';
import useMediaQuery from 'lib/use-media-query';
import { AnimationAppear, AnimationObservableAppearance } from 'components/animations/animations';

export type PageHeroBlockAboutPropsType = {
    isFullScreen?: boolean;
    title: string;
    extraTitle?: string;
    firstImageSrc: string;
    secondImageSrc: string;
};

const PageHeroBlockAbout = (props: PageHeroBlockAboutPropsType) => {
    const { isFullScreen, title, extraTitle, firstImageSrc, secondImageSrc } = props;
    const isBreakpoint = useMediaQuery();

    return (
        <HeroBlock
            isFullScreen={isFullScreen}
            extraClass="hero-block--with-header hero-block--extra-margin"
        >
            <Columns hasMobileLayout={true}>
                <ColumnsItem size={ColumnsItem.size['1/1']} justifyContentCenter={isBreakpoint}>
                    <AnimationObservableAppearance>
                        <>
                            <Header1 html={title} />
                            {extraTitle ? <Header1 html={extraTitle} /> : null}
                        </>
                    </AnimationObservableAppearance>
                </ColumnsItem>
                <ColumnsItem
                    size={ColumnsItem.size['1/1']}
                    marginLeft={isBreakpoint ? undefined : 105}
                >
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <AnimationAppear y={isBreakpoint ? 100 : 200}>
                            <MediaCard
                                src={firstImageSrc}
                                width={isBreakpoint ? 132 : 257}
                                height={isBreakpoint ? 270 : 521}
                                hasShadow={true}
                            />
                        </AnimationAppear>
                        <div
                            style={{
                                marginLeft: 18,
                                transform: 'translateY(50%)',
                            }}
                        >
                            <AnimationAppear
                                y={isBreakpoint ? 200 : 500}
                                delay={isBreakpoint ? 100 : 300}
                            >
                                <MediaCard
                                    src={secondImageSrc}
                                    width={isBreakpoint ? 132 : 257}
                                    height={isBreakpoint ? 270 : 521}
                                    hasShadow={true}
                                />
                            </AnimationAppear>
                        </div>
                    </div>
                </ColumnsItem>
            </Columns>
        </HeroBlock>
    );
};

export default PageHeroBlockAbout;
