import { AnimationObservableAppearance } from 'components/animations/animations';
import PageSectionTwoColumns from 'components/page-section-two-columns/page-section-two-columns';
import TextSection, { TextSectionTitleStyle } from 'components/text-section/text-section';
import React from 'react';
import { BlockProps } from '../index.types';

type HeroImageType = {
    block: BlockProps;
} & {
    caption?: string;
    title?: string;
};

export default function PageAnimatedTextSection({ block, ...props }: HeroImageType) {
    return (
        <PageSectionTwoColumns
            left={
                <>
                    <TextSection caption={props.caption} />
                    <AnimationObservableAppearance>
                        <TextSection
                            caption={' '}
                            title={props.title}
                            titleStyle={TextSectionTitleStyle.H2}
                        />
                    </AnimationObservableAppearance>
                </>
            }
        />
    );
}
