import React from 'react';
import { BlockProps } from '../index.types';
import PageQuotesCarousel, {
    QuoteCardType,
} from 'components/page-quotes-carousel/page-quotes-carousel-dynamic';

const INIT_ITEM = {
    name: '',
    position: '',
    text: '',
    imageSrc: '',
};

function setQuoteList(data: BlockProps['block']['innerBlocks']) {
    const list: QuoteCardType[] = [];

    data?.forEach((innerBlock) => {
        const listItem: QuoteCardType = Object.create(INIT_ITEM);

        const name = innerBlock?.attributes?.filter((attribute) => attribute?.name === 'name')[0]
            ?.value;

        if (name) {
            listItem.name = name;
        }

        const position = innerBlock?.attributes?.filter(
            (attribute) => attribute?.name === 'position'
        )[0]?.value;

        if (position) {
            listItem.position = position;
        }

        const text = innerBlock?.attributes?.filter((attribute) => attribute?.name === 'text')[0]
            ?.value;

        if (text) {
            listItem.text = text;
        }

        const imageSrc = innerBlock?.attributes?.filter(
            (attribute) => attribute?.name === 'imageSrc'
        )[0]?.value;

        if (imageSrc) {
            listItem.imageSrc = imageSrc;
        }

        list.push(listItem);
    });

    return list;
}

type PageQuotesCarouselSectionType = BlockProps;

export default function PageQuotesCarouselSectionBlock({ block }: PageQuotesCarouselSectionType) {
    const quotes = setQuoteList(block.innerBlocks);

    return <PageQuotesCarousel cards={quotes} />;
}
