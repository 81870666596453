/* eslint-disable @babel/no-unused-expressions */
import React from 'react';
import ValuesList, { ValueProps } from 'components/values-list/values-list-dynamic';
import useMediaQuery from 'lib/use-media-query';
import PageValuesCarousel, {
    PageValuesCarouselCards,
} from '../page-values-carousel/page-values-carousel-dynamic';
import ModalContext from 'components/modal-context';
import { ModalColorScheme } from 'components/modal/modal.config';

export type PageValuesListProps = {
    title?: string;
    values: PageValuesCarouselCards;
};

const PageValuesList: React.FC<PageValuesListProps> = ({ title, values }) => {
    const isBreakpoint = useMediaQuery(800);
    const { setModalOpen, setModalContent, setModalColor, setScrollY } =
        React.useContext(ModalContext);

    const modalContent = (valueIndex: number) => (
        <PageValuesCarousel initialSlide={valueIndex} cards={values} />
    );

    const onCLickHandler = (valueId: number) => (_event: React.MouseEvent<HTMLLIElement>) => {
        setScrollY &&
            setScrollY(
                () => global.document.body.scrollTop || global.document.documentElement.scrollTop
            );
        setModalOpen && setModalOpen(true);
        setModalContent && setModalContent(modalContent(valueId));
        setModalColor && setModalColor(ModalColorScheme.Primary);
    };

    const valuesList: ValueProps[] = values.map((value, index) => {
        return {
            onClick: onCLickHandler(index),
            ...value,
        };
    });

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <ValuesList title={title} values={valuesList} isSpacious={!isBreakpoint} />
        </div>
    );
};

export default PageValuesList;
