import React, { useContext } from 'react';
import Link from 'components/link/link';
import LocaleContext from 'lib/locale-context';

type FooterLinkType = {
    getUrl?: (lang: string) => string;
    url?: string;
    title?: string;
    isExternal?: boolean;
};

type Props = FooterLinkType & {
    list?: FooterLinkType[];
    titleList?: FooterLinkType[];
    content?: React.ReactNode;
    socialLinks?: React.ReactNode;
    appsLinks?: React.ReactNode;
    className?: string;
};

function FooterMenu(props: Props) {
    const {
        getUrl,
        url,
        title,
        isExternal,
        titleList,
        list,
        content,
        socialLinks,
        appsLinks,
        className,
    } = props;

    const lang = useContext(LocaleContext);

    const titleUrl = url || (getUrl && getUrl(lang));

    return (
        <div className="footer-menu">
            {titleList ? null : (
                <h3 className="footer-menu__title">
                    {titleUrl ? (
                        <Link
                            className={`footer-menu__title-link ${className || ''}`}
                            href={titleUrl}
                            title={title}
                            target={isExternal ? '_blank' : undefined}
                        >
                            {title}
                        </Link>
                    ) : (
                        title
                    )}
                </h3>
            )}

            {list ? (
                <ul className="footer-menu__list">
                    {list.map(
                        (
                            {
                                getUrl: listGetUrl,
                                title: listTitle,
                                url: listUrl,
                                isExternal: listIsExternal,
                            },
                            index
                        ) => {
                            const itemUrl = listUrl || (listGetUrl && listGetUrl(lang));

                            return (
                                <li className="footer-menu__item" key={`footer-menu-item-${index}`}>
                                    <Link
                                        className="footer-menu__link"
                                        href={itemUrl}
                                        title={listTitle}
                                        target={listIsExternal ? '_blank' : undefined}
                                    >
                                        {listTitle}
                                    </Link>
                                </li>
                            );
                        }
                    )}
                </ul>
            ) : null}

            {titleList ? (
                <ul className="footer-menu__list footer-menu__list--titles">
                    {titleList.map(
                        (
                            {
                                getUrl: listGetUrl,
                                title: listTitle,
                                url: listUrl,
                                isExternal: listIsExternal,
                            },
                            index
                        ) => {
                            const itemUrl = listUrl || (listGetUrl && listGetUrl(lang));

                            return (
                                <li className="footer-menu__item" key={`footer-menu-item-${index}`}>
                                    <h3 className="footer-menu__title">
                                        <Link
                                            className="footer-menu__link"
                                            href={itemUrl}
                                            title={listTitle}
                                            target={listIsExternal ? '_blank' : undefined}
                                        >
                                            {listTitle}
                                        </Link>
                                    </h3>
                                </li>
                            );
                        }
                    )}
                </ul>
            ) : null}

            {content ? <div className="footer-menu__content">{content}</div> : null}

            {socialLinks ? <div className="footer-menu__social-links">{socialLinks}</div> : null}

            {appsLinks ? <div className="footer-menu__apps-links">{appsLinks}</div> : null}
        </div>
    );
}

export default FooterMenu;
