import React from 'react';
import { BlockProps } from '../index.types';
import PageSectionFullscreenImage from 'components/page-section-fullscreen-image/page-section-fullscreen-image';

type HeroImageType = {
    block: BlockProps;
} & {
    mediaURL?: string;
    mediaPortraitURL?: string;
    aspectRatio?: number;
};

export default function HeroImage({ block, ...props }: HeroImageType) {
    return (
        <PageSectionFullscreenImage
            src={props.mediaURL}
            srcPortrait={props.mediaPortraitURL}
            aspectRatio={props.aspectRatio}
        />
    );
}
