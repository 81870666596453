import React from 'react';
import HeroBlock from 'components/hero-block/hero-block';
import MediaCard from 'components/media-card/media-card';
import useMediaQuery from 'lib/use-media-query';

export type SuccessStory = {
    imageSrc: string;
    title: string;
    text: string;
};

type PageSuccessStoriesProps = { stories: SuccessStory[] };

const PageSuccessStories: React.FC<PageSuccessStoriesProps> = ({ stories }) => {
    const isBreakpoint = useMediaQuery();
    const width = isBreakpoint ? global.window.innerWidth / 2 - 16 : 346;
    const height = isBreakpoint ? (global.window.innerWidth / 2 - 16) * 1.92 : 602;

    return (
        <HeroBlock isFluid={true}>
            <div className="success-stories">
                {stories.map((item) =>
                    item.imageSrc ? (
                        <div className="success-stories__item" key={item.imageSrc}>
                            <MediaCard
                                hasShadow={true}
                                layout="responsive"
                                width={width}
                                height={height}
                                src={item.imageSrc}
                                {...item}
                            />
                        </div>
                    ) : null
                )}
            </div>
        </HeroBlock>
    );
};

export default PageSuccessStories;
