import React, { useEffect, useState } from 'react';
import Image from 'lib/image-container';
import throttle from 'lib/throttle';
import { useSpring, animated } from 'react-spring';

type HeroImageProps = {
    src: string;
    aspectRatio?: number;
    overlay?: React.ReactNode;
    hasParallax?: boolean;
};

const HeroImage: React.FC<HeroImageProps> = (props) => {
    const { src, aspectRatio = 1440 / 700, overlay, hasParallax } = props;
    const [overlap, setOverlap] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    // parameters of container - position and height
    const [offsets, setOffsets] = React.useState<{
        offsetTop: number;
        offsetHeight: number;
    } | null>(null);

    // block container
    const elementRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        const { current } = elementRef;

        // check and remember window height for further calculations (performance improvement)
        if (windowHeight === 0) {
            setWindowHeight(global.window.innerHeight);
        }

        // check sizes for the hero image
        if (current && offsets === null) {
            // check and remember block position for further calculations (performance improvement)
            // timeout is necessary to render block css and calculate position properly
            setTimeout(() => {
                setOffsets({
                    offsetTop: current.getBoundingClientRect().top,
                    offsetHeight: current.offsetHeight,
                });
            }, 300);
        }

        // detect and set scroll position
        const handleScroll = () => {
            if (offsets && windowHeight) {
                const scrollTop = global.window.scrollY;

                // the block animation should be process during time
                // when the block is travelling through the screen
                // overlapPercentage shows progress of this travel
                let overlapPercentage = 0;

                // for cases when the hero image is visible initially we set overlap percentage to 0
                // to let users see the top of the image
                if (offsets.offsetTop < windowHeight) {
                    overlapPercentage = scrollTop / (offsets.offsetHeight + offsets.offsetTop);
                } else {
                    overlapPercentage =
                        (scrollTop - offsets.offsetTop) / (offsets.offsetHeight + windowHeight);
                }

                if (overlapPercentage < 0) {
                    overlapPercentage = 0;
                } else if (overlapPercentage > 1) {
                    overlapPercentage = 1;
                }

                setOverlap(overlapPercentage);
            }
        };

        global.window.addEventListener('scroll', throttle(handleScroll, 30));
        return () => global.window.removeEventListener('scroll', throttle(handleScroll, 30));
    }, [offsets, windowHeight]);

    const [{ springOverlap }, springSetOverlap] = useSpring(() => ({
        springOverlap: 0,
    }));

    springSetOverlap({ springOverlap: overlap });

    const backgroundSlide = 150;
    const shiftSpeedCoefficient = 1.1; // background image slides faster to let a user check it whole
    const mediaStyles = springOverlap.interpolate((strOverlap) => {
        const shift = Math.min(
            backgroundSlide,
            (strOverlap as number) * shiftSpeedCoefficient * backgroundSlide
        );
        return `translateY(${-shift}px)`;
    });

    // TODO: review width and height behaviour
    return (
        <div
            className="hero-image"
            style={{
                ['--aspect-ratio' as string]: aspectRatio,
            }}
            ref={elementRef}
        >
            <animated.div
                className="hero-image__media"
                style={
                    hasParallax ? { transform: mediaStyles, bottom: -backgroundSlide } : undefined
                }
            >
                <Image src={src} alt="" fill={true} />
            </animated.div>
            {overlay ? <div className="hero-image__overlay">{overlay}</div> : null}{' '}
        </div>
    );
};

export default HeroImage;
