import React from 'react';

const BenefitsOfficeCardsWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <div className="benefits-office-cards">
            <div className="benefits-office-cards__inner">{children}</div>
        </div>
    );
};

export default BenefitsOfficeCardsWrapper;
