import React from 'react';
import Link from 'components/link/link';

type Props = {
    items?: {
        url?: string;
        title?: string | React.ReactNode;
    }[];
    url?: string;
    title?: string | React.ReactNode;
};

function MobileMenu(props: Props) {
    const { title, url, items } = props;
    return (
        <nav className="mobile-menu">
            <div className="mobile-menu__title">
                <Link className="link" href={url}>
                    <span className="link__text">{title}</span>
                </Link>
            </div>

            {items ? (
                <ul className="mobile-menu__list">
                    {items.map((item, index) => {
                        const { url: itemUrl, title: itemTitle } = item;

                        return (
                            <li className="mobile-menu__item" key={`mobile-menu-item-${index}`}>
                                <Link className="mobile-menu__link" href={itemUrl}>
                                    {itemTitle}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            ) : null}
        </nav>
    );
}

export default MobileMenu;
