import { ContentBlockAttribute, Maybe } from 'interfaces/team-bumble-microsite-cms';
import React from 'react';
import { BlockProps } from '../index.types';
import styles from './UnsupportedBlock.module.css';

const AtrributesBlock: React.FC<{ attributes: Maybe<ContentBlockAttribute>[] }> = ({
    attributes,
}) => (
    <ul>
        {attributes &&
            attributes.map((attr, i) =>
                attr ? (
                    <li key={i}>
                        <strong>{attr.name}</strong>: {attr.value || 'null'}
                    </li>
                ) : null
            )}
    </ul>
);

// @TODO add support of several depth levels
export default function UnsupportedBlock({
    block: { name, tagName, attributes, innerBlocks, outerHTML },
}: BlockProps) {
    const html = outerHTML;

    return (
        <div className={styles.container}>
            <h4 className={styles.title}>
                <strong>Unsupported block</strong>: <code>{name}</code>
            </h4>
            {tagName && <h5>{tagName}</h5>}
            {html && <blockquote>{html}</blockquote>}
            {attributes && attributes.length > 0 && <AtrributesBlock attributes={attributes} />}
            {innerBlocks && innerBlocks.length > 0 && (
                <ul style={{ marginLeft: 20 }}>
                    {innerBlocks.map((block, blockIndex) =>
                        block ? (
                            <li key={blockIndex}>
                                <strong>Unsupported block</strong>: <code>{block.name}</code>
                                {block.attributes && block.attributes.length > 0 && (
                                    <AtrributesBlock attributes={block.attributes} />
                                )}
                                {block.innerBlocks && block.innerBlocks.length > 0 && (
                                    <ul style={{ marginLeft: 20 }}>
                                        {block.innerBlocks.map((innerBlock, innerIndex) =>
                                            innerBlock ? (
                                                <li key={innerIndex}>
                                                    <strong>Unsupported block</strong>:{' '}
                                                    <code>{innerBlock.name}</code>
                                                    {innerBlock.attributes &&
                                                        innerBlock.attributes.length > 0 && (
                                                            <AtrributesBlock
                                                                attributes={innerBlock.attributes}
                                                            />
                                                        )}
                                                </li>
                                            ) : null
                                        )}
                                    </ul>
                                )}
                            </li>
                        ) : null
                    )}
                </ul>
            )}
        </div>
    );
}
