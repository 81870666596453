import React from 'react';
import classNames from 'classnames';
import Image from 'lib/image-container';
import Header3 from 'components/typography/header-3';
import Header4 from 'components/typography/header-4';

type MediaCardLayout = 'fixed' | 'intrinsic' | 'responsive' | undefined;
type MediaCardDefaultLayout = 'fill';

type MediaCardProps = {
    title?: React.ReactNode;
    text?: React.ReactNode;
    height?: string | number;
    width?: string | number;
    src?: string;
    jsClass?: string;
    hasShadow?: boolean;
    // borderRadius?: number;
    layout?: MediaCardDefaultLayout | MediaCardLayout;
};

const MediaCard: React.FC<MediaCardProps> = ({
    title,
    text,
    height = 306,
    width = '100%',
    src,
    jsClass,
    hasShadow,
    layout,
}) => {
    const classnames = {
        block: classNames(
            {
                'media-card': true,
                'has-shadow': hasShadow,
            },
            typeof jsClass !== 'undefined' && jsClass
        ),
    };

    let image = src ? (
        <Image
            className="media-card__back-image"
            src={src}
            alt=""
            width={typeof width === 'number' ? width : undefined}
            height={typeof height === 'number' ? height : undefined}
            fill={true}
        />
    ) : null;

    // @todo: temporary - rethink
    if (layout !== 'fill') {
        image = src ? (
            <Image
                className="media-card__back-image"
                src={src}
                alt=""
                width={typeof width === 'number' ? width : undefined}
                height={typeof height === 'number' ? height : undefined}
            />
        ) : null;
    }

    return (
        <div className={classnames.block}>
            <div className="media-card__back">{image}</div>
            {title || text ? (
                <div className="media-card__front">
                    {title ? (
                        <div className="media-card__front-title">
                            <Header3 isInherited={true}>{title}</Header3>
                        </div>
                    ) : null}
                    {text ? (
                        <div className="media-card__front-text">
                            <Header4 isInherited={true}>{text}</Header4>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default MediaCard;
