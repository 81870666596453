import React from 'react';
import cx from 'classnames';
import ValuesCarouselCard from './values-carousel-card-dynamic';
import Slider, { ArrowsLayout } from 'components/slider/slider';
import Caption from 'components/typography/caption';

type ValuesCard = {
    title: React.ReactNode;
    text: React.ReactNode;
    imagePreviewUrl?: string;
    isSpacious?: boolean;
    onClickClose?: (event?: React.MouseEvent) => void;
};

export type ValuesCarouselProps = {
    title?: React.ReactNode;
    cards: ValuesCard[];
    isSpacious?: boolean;
    initialSlide?: number;
};

const ValuesCarousel: React.FunctionComponent<ValuesCarouselProps> = ({
    title,
    cards,
    isSpacious,
    initialSlide,
}) => {
    const className = cx({
        'values-carousel': true,
        'values-carousel--spacious': isSpacious,
    });

    return (
        <div className={className}>
            {title ? (
                <div className="values-carousel__title">
                    <Caption>{title}</Caption>
                </div>
            ) : null}

            <div className="values-carousel__cards">
                <Slider
                    index={initialSlide}
                    loop={true}
                    containerModifierClass="swiper--arrows-outside-"
                    spaceBetween={120}
                    navigation={true}
                    arrowColorSecondary={true}
                    arrowsLayout={ArrowsLayout.Relative}
                >
                    {cards.map((item, index) => {
                        return (
                            <ValuesCarouselCard
                                key={`${item.title}${index}`}
                                title={item.title}
                                description={item.text}
                                imagePreviewUrl={item.imagePreviewUrl}
                                isSpacious={isSpacious}
                                onClickClose={item.onClickClose}
                            />
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

export default ValuesCarousel;
