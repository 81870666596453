import React from 'react';
import { BlockProps } from '../index.types';
import PageSectionFullscreenImage from 'components/page-section-fullscreen-image/page-section-fullscreen-image';
import useMediaQuery from 'lib/use-media-query';

type HeroImageType = {
    block: BlockProps;
} & {
    mediaURL?: string;
    mediaPortraitURL?: string;
    aspectRatio?: number;
};

export default function HeroImage({ block, ...props }: HeroImageType) {
    const isBreakpointTablet = useMediaQuery();

    return (
        <PageSectionFullscreenImage
            src={props.mediaURL}
            srcPortrait={props.mediaPortraitURL}
            // @TODO - reconsider later,
            // the only difference with HeroBlock is condition on 900px
            aspectRatio={props.aspectRatio || isBreakpointTablet ? 375 / 454 : undefined}
        />
    );
}
