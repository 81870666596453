import React from 'react';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import PageSectionTwoColumns from 'components/page-section-two-columns/page-section-two-columns';
import TextSection, {
    TextSectionTitleStyle,
    TextSectionAlign,
} from 'components/text-section/text-section';
import ColumnMediaCard from 'components/column-media-card/column-media-card';
import ColumnMediaCardWrapper from 'components/column-media-card/column-media-card-wrapper';
import { MobileCaption } from 'components/typography';

export type PageAboutAppSectionProps = {
    caption?: string;
    title: string;
    text: string;
    extraText?: string;
    imageSrc: string;
    imageAspectRatio?: number;
    imagePosition?: 'left' | 'right';
    mediaquery?: MediaQueryWidth;
    brand?: 'bumble' | 'badoo';
};

const PageAboutAppSection: React.FC<PageAboutAppSectionProps> = (props) => {
    const {
        caption,
        title,
        text,
        extraText,
        imageSrc,
        imagePosition = 'left',
        mediaquery = 640,
        brand = 'bumble',
    } = props;

    const isBreakpoint = useMediaQuery(mediaquery);
    const imageAspectRatio = 358 / 726;

    const textBlock = (
        <TextSection
            caption={isBreakpoint ? undefined : caption}
            titleStyle={TextSectionTitleStyle.H2}
            title={title}
            text={text}
            extraText={extraText}
            align={isBreakpoint ? TextSectionAlign.CENTERED : undefined}
        />
    );

    const imageBlock = (
        <>
            {isBreakpoint && caption ? <MobileCaption>{caption}</MobileCaption> : null}
            <ColumnMediaCardWrapper
                maxHeight={isBreakpoint ? '366px' : '726px'}
                maxWidth={isBreakpoint ? '180px' : '358px'}
                marginBottom={isBreakpoint ? '56px' : 0}
            >
                <ColumnMediaCard
                    hasShadow={true}
                    src={imageSrc}
                    aspectRatio={imageAspectRatio}
                    // @TODO make it as prop to show text over image
                    // title={isBreakpoint ? title : undefined}
                />
            </ColumnMediaCardWrapper>
        </>
    );

    return (
        <PageSectionTwoColumns
            extraClass={`page__section--padded ${brand}`}
            isMobileOrderInverted={imagePosition === 'right'}
            right={textBlock}
            left={imageBlock}
        />
    );
};

export default PageAboutAppSection;
