import React, { useCallback, useState } from 'react';
import { Lexeme } from '@bumble/localization/components';
import MobilePanel from 'components/mobile-panel/mobile-panel';
import MobileMenu from 'components/mobile-menu/mobile-menu';
import Logo from 'components/logo/logo';
import Button from 'components/button/button';
import Icon from 'components/icon/icon';
import lexemes from 'components/lexemes/index/lexemes';
import { routes } from 'lib/constants-team-bumble';

const mobileMenuItemsTeamBumble = [
    {
        title: <Lexeme path="bumblesite@team_bumble.page_index.navigation.links.about" />,
        url: routes.about,
    },
    {
        title: <Lexeme path="bumblesite@team_bumble.page_index.navigation.links.life" />,
        url: routes.life,
    },
    {
        title: <Lexeme path="bumblesite@team_bumble.page_index.navigation.links.teams" />,
        url: routes.teams,
    },
    {
        title: <Lexeme path="bumblesite@team_bumble.page_index.navigation.links.benefits" />,
        url: routes.benefits,
    },
];

export default function PageMobilePanel() {
    const [isActive, setIsActive] = useState(false);

    const closePanel = useCallback(() => {
        global.document.body.classList.toggle('is-opened-mobile-nav', false);
        setIsActive(false);
    }, []);

    return (
        <MobilePanel
            logo={<Logo color="inhert" isOld={false} isNew={true} />}
            isActive={isActive}
            closePanel={closePanel}
            closeAction={
                <Button
                    isCircle={true}
                    jsClass="js-close-menu"
                    icon={<Icon size="md" name={Icon.Name.Close} />}
                    onClick={closePanel}
                />
            }
            menuList={[
                ...mobileMenuItemsTeamBumble.map((menuItem, index) => (
                    <MobileMenu {...menuItem} key={`item-${index}`} />
                )),
                <Button
                    text={lexemes.lxNavigationOpenRoles}
                    href={routes.open_roles}
                    color={Button.color.Primary}
                    designType={Button.designType.FILLED}
                    inline={true}
                    extraClass="button--lg-text"
                    key="open-roles-action"
                />,
            ]}
        />
    );
}
