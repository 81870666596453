import Localization from 'lib/localization';

export default {
    lxBumbleHiringBlockTitle(params: { page_partner_name: string }) {
        return Localization.get('bumblesite@team_bumble.sharing_assets.hiring_block.title', params);
    },
    get lxBumbleHiringBlockText() {
        return Localization.get('bumblesite@team_bumble.sharing_assets.hiring_block.text');
    },
    lxBumbleHomepageTabTitle(params: { page_partner_name: string }) {
        return Localization.get(
            'bumblesite@team_bumble.sharing_assets.bumble_homepage_tab.title',
            params
        );
    },
    get lxBumbleLifeTabTitle() {
        return Localization.get('bumblesite@team_bumble.sharing_assets.bumble_life_tab.title');
    },
};
