import React from 'react';
import classNames from 'classnames';
import Icon from 'components/icon/icon';
import Loader, { LoaderSize } from 'components/loader/loader';
import Image from 'lib/image-container';
// import './media-box.scss';

export enum MediaBoxAspect {
    PORTRAIT = 'PORTRAIT',
    SQUARE = 'SQUARE',
    LANDSCAPE = 'LANDSCAPE',
    FREE = 'FREE',
    WIDE_LANDSCAPE = 'WIDE_LANDSCAPE',
    NONE = 'NONE',
    IPHONE6 = 'IPHONE6',
    BENEFITS = 'BENEFITS',
}

const mapAspectToClasses = {
    [MediaBoxAspect.PORTRAIT]: 'aspect--portrait',
    [MediaBoxAspect.SQUARE]: 'aspect--square',
    [MediaBoxAspect.LANDSCAPE]: 'aspect--landscape',
    [MediaBoxAspect.WIDE_LANDSCAPE]: 'aspect--wide-landscape',
    [MediaBoxAspect.FREE]: 'aspect--free',
    [MediaBoxAspect.NONE]: 'aspect--none',
    [MediaBoxAspect.IPHONE6]: 'aspect--phone',
    [MediaBoxAspect.BENEFITS]: 'aspect--benefits-card',
};

export enum MediaBoxType {
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
}

type MediaBoxProps = {
    aspect?: MediaBoxAspect;
    autoplay?: boolean;
    external?: boolean;
    type?: MediaBoxType;
    src?: string;
    video?: string;
    title?: string;
    isFluid?: boolean;
    jsClass?: string;
    jsPlayActionClass?: string;
    hasLoader?: boolean;
    hasShadow?: boolean;
    hasExtraShadow?: boolean;
    hasVideoOpenAnimation?: boolean;
    withinFrame?: boolean;
    volume?: string | number;
    label?: string;
    /**
     * has specific mobile platform behaviour
     */
    mobile?: boolean;
    onExternalPlayClick?: (event: { target: any; data: number }) => void;
    titleElement?: React.ReactNode;
    iconPlayColor?: 'primary';
    isVideoMock?: boolean;
    children?: React.ReactNode;
};

// eslint-disable-next-line complexity
const MediaBox: React.FC<React.PropsWithChildren<MediaBoxProps>> = (props) => {
    const {
        aspect,
        autoplay,
        external,
        type = MediaBoxType.IMAGE,
        src,
        video,
        title,
        children,
        isFluid,
        jsClass,
        jsPlayActionClass = 'js-action-play-button',
        hasLoader,
        hasShadow,
        hasExtraShadow,
        hasVideoOpenAnimation,
        volume,
        label,
        mobile,
        withinFrame,
        onExternalPlayClick,
        titleElement,
        iconPlayColor,
        isVideoMock,
    } = props;
    const classnames = {
        block: classNames(
            {
                'media-box': true,
                'is-fluid': isFluid,
                'has-shadow': hasShadow,
                'has-extra-shadow': hasExtraShadow,
                'has-open-animation': hasVideoOpenAnimation,
                'is-within-frame': withinFrame,
            },
            typeof aspect !== 'undefined' && mapAspectToClasses[aspect],
            typeof jsClass !== 'undefined' && jsClass
        ),
        playButton: classNames({
            'media-box__video-play-button': true,
            'media-box__video-play-button--with-title': titleElement,
            [jsPlayActionClass]: jsPlayActionClass,
        }),
    };
    const mediaBoxPreviewElement = (
        <div
            className="media-box__video-preview js-video-preview-action"
            style={{ backgroundImage: src ? `url(${src})` : undefined }}
            // alt={title || ''}
        />
    );
    const mediaBoxElement = (
        <>
            <figure
                className={classnames.block}
                data-video-id={video}
                data-video-label={label}
                data-video-volume={volume}
                data-video-animated={hasVideoOpenAnimation}
                data-autoplay={autoplay}
                data-external={external}
                // @ts-ignore
                onClick={onExternalPlayClick}
            >
                {type === MediaBoxType.IMAGE ? (
                    <picture className="media-box__picture">
                        {src && (
                            <Image
                                className="media-box__picture-image"
                                src={src}
                                alt={title || ''}
                                loading="lazy"
                                fill={true}
                            />
                        )}

                        {children}

                        {!src && <div className="media-box__placeholder" />}
                    </picture>
                ) : null}

                {type === MediaBoxType.VIDEO ? (
                    <React.Fragment>
                        <div className="media-box__video">
                            {src && mediaBoxPreviewElement}

                            {children}

                            {!src && <div className="media-box__placeholder" />}
                        </div>

                        {hasLoader && (
                            <div className="media-box__video-loader">
                                <Loader
                                    size={LoaderSize.LARGE}
                                    extraClass="is-hidden"
                                    jsClass="js-video-loader"
                                />
                            </div>
                        )}

                        <div className={classnames.playButton}>
                            {titleElement ? (
                                <div className="media-box__video-play-button-title">
                                    {titleElement}
                                </div>
                            ) : null}

                            <div className="media-box__video-play-button-icon">
                                {iconPlayColor === 'primary' ? (
                                    <Icon
                                        name={Icon.Name.MediaPlayCircleHollow}
                                        size="stretch"
                                        color="primary"
                                    />
                                ) : (
                                    <Icon name="icon-media-play-circle" size="stretch" />
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                ) : null}
            </figure>
            {type === MediaBoxType.VIDEO && !isVideoMock ? (
                <div
                    className={`media-box-external-video js-video-preview ${
                        mobile ? 'is-mobile' : ''
                    }`}
                />
            ) : null}
            {type === MediaBoxType.VIDEO && hasVideoOpenAnimation && !isVideoMock ? (
                <div className="media-box-video-close js-video-close">
                    <Icon name="icon-video-close" size="stretch" />
                </div>
            ) : null}
        </>
    );

    if (withinFrame) {
        return <div className="media-box-wrapper">{mediaBoxElement}</div>;
    }

    return mediaBoxElement;
};

MediaBox.defaultProps = {
    aspect: MediaBoxAspect.PORTRAIT,
};

// MediaBox.type = MediaBoxType;

export default MediaBox;
