import React from 'react';
import ValuesCarousel, {
    ValuesCarouselProps,
} from 'components/values-carousel/values-carousel-dynamic';
import useMediaQuery from 'lib/use-media-query';
import lexemes from 'components/lexemes/life/lexemes';

export type PageValuesCarouselCards = ValuesCarouselProps['cards'];

type PageValuesCarouselProps = {
    onClick?: (event?: React.MouseEvent) => void;
    initialSlide?: number;
    cards?: PageValuesCarouselCards;
};

const PageValuesCarousel: React.FC<PageValuesCarouselProps> = (props) => {
    const isBreakpoint = useMediaQuery(800);
    const clickHandler = {
        onClickClose: props.onClick,
    };

    const cards: ValuesCarouselProps['cards'] | undefined =
        props.cards &&
        props.cards.map((item) => {
            return {
                ...item,
                ...clickHandler,
            };
        });

    return cards ? (
        <ValuesCarousel
            title={lexemes.lxValuesBlockHeader}
            cards={cards}
            initialSlide={props.initialSlide}
            isSpacious={!isBreakpoint}
        />
    ) : null;
};

export default PageValuesCarousel;
