import React from 'react';
import Header3 from '../typography/header-3';
import Caption from '../typography/caption';
import ArrowRight from 'public/assets/generic-chevron-forward.svg';

export type BenefitsOfficeCardProps = {
    src?: React.ReactNode;
    city?: string;
    cityEditor?: React.ReactNode;
    country?: string;
    countryEditor?: React.ReactNode;
    url?: string;
    urlEditor?: React.ReactNode;
    subtitle?: string;
    subtitleEditor?: React.ReactNode;
    isEditable?: boolean;
    LinkComponent: React.FC<
        React.PropsWithChildren<{
            className?: string;
            href?: string;
        }>
    >;
};

const BenefitsOfficeCard: React.FC<BenefitsOfficeCardProps> = ({
    src,
    url,
    urlEditor,
    city,
    cityEditor,
    country,
    countryEditor,
    subtitle,
    subtitleEditor,
    LinkComponent,
    isEditable = false,
}) => {
    const editorBlock = isEditable ? (
        <>
            {urlEditor}
            <br />
            <br />
            {cityEditor}
            {subtitleEditor && <div className="benefits-office-card__soon">{subtitleEditor}</div>}
        </>
    ) : (
        <>
            {city}
            {subtitle && <div className="benefits-office-card__soon">{subtitle}</div>}
        </>
    );
    return (
        <LinkComponent className="benefits-office-card" href={url}>
            <div className="benefits-office-card__back">{src}</div>
            <div className="benefits-office-card__front">
                <div className="benefits-office-card__title">
                    <Header3 htmlEditor={editorBlock} />
                </div>
                <div className="benefits-office-card__text">
                    <div className="benefits-office-card__icon">
                        <ArrowRight />
                    </div>
                    <Caption
                        isMedium={true}
                        html={country}
                        htmlEditor={isEditable ? countryEditor : null}
                    />
                </div>
            </div>
        </LinkComponent>
    );
};

export default BenefitsOfficeCard;
