/* eslint-disable @babel/no-unused-expressions */
import React from 'react';
import cx from 'classnames';
import Icon from 'components/icon/icon';
import Loader, { LoaderSize } from 'components/loader/loader';
import virtualUnitsPolyFill from 'lib/virtual-units-polyfill';
import { ModalColorScheme } from './modal.config';
import ModalContext from 'components/modal-context';

const mapColorToClassname: { [key in ModalColorScheme]: string } = {
    [ModalColorScheme.None]: '',
    [ModalColorScheme.Primary]: 'modal--primary',
    [ModalColorScheme.Secondary]: 'modal--secondary',
};

type Props = {
    jsBlockClass?: string;
    jsFadeClass?: string;
    jsCloseClass?: string;
    jsContentClass?: string;
    icon?: React.ReactNode;
    isActive?: boolean;
    iframeUrl?: string;
    iframeReload?: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
    maxWidth?: number;
    color?: ModalColorScheme;
};

function Modal(props: Props) {
    const {
        jsBlockClass = 'js-modal',
        jsFadeClass = 'js-modal-close',
        jsCloseClass = 'js-modal-close',
        jsContentClass = 'js-modal-content',
        icon = <Icon size="md" name={Icon.Name.Close} />,
        onClose,
        isActive,
        iframeUrl,
        iframeReload,
        children,
        maxWidth,
        color,
    } = props;
    const [isLoading, setIsLoading] = React.useState(true);
    const { scrollY, setModalOpen, setModalContent } = React.useContext(ModalContext);
    const inframeEl = React.useRef<HTMLIFrameElement>(null);

    const className = {
        block: cx(
            {
                modal: true,
                'is-active': isActive,
                [jsBlockClass]: jsBlockClass,
            },
            typeof color !== 'undefined' && [mapColorToClassname[color]]
        ),
        fade: cx({
            modal__fade: true,
            [jsFadeClass]: jsFadeClass,
        }),
        closeAction: cx({
            modal__close: true,
            [jsCloseClass]: jsCloseClass,
        }),
        content: cx({
            modal__content: true,
            [jsContentClass]: jsContentClass,
            'is-hidden': isLoading && iframeReload,
        }),
    };

    const onModalCloseHandler = React.useCallback(() => {
        setModalOpen && setModalOpen(false);
        setModalContent && setModalContent(<></>);
    }, [setModalOpen, setModalContent]);

    const onCloseClickHandler = () => {
        setIsLoading(true);

        if (onClose) {
            onClose();
        }

        onModalCloseHandler();
    };

    React.useEffect(() => {
        const isMobile =
            global.document.documentElement.classList.contains('android') ||
            global.document.documentElement.classList.contains('ios');
        virtualUnitsPolyFill();
        global.document.documentElement.style.overflow = isActive ? 'hidden' : '';
        global.document.documentElement.style.maxHeight = isActive ? '100vh' : '';

        if (isActive) {
            if (isMobile) {
                global.document.documentElement.classList.add('has-no-scroll');
            }
        } else {
            if (isMobile) {
                global.document.documentElement.classList.remove('has-no-scroll');
            }
            // after we set state, we need to scroll back
            scrollY && global.window.scrollTo(0, scrollY);
        }

        const globalKeyListener = (event: KeyboardEvent) => {
            event.stopImmediatePropagation();

            if (isActive) {
                if (event.key === 'Escape') {
                    onClose && onClose();
                    onModalCloseHandler();
                }
            }
        };

        global.document.addEventListener('keydown', globalKeyListener, false);

        return function cleanup() {
            global.document.removeEventListener('keydown', globalKeyListener, false);
        };
    }, [isActive, onClose, scrollY, onModalCloseHandler]);

    return (
        <div className={className.block}>
            <div className={className.fade} onClick={onCloseClickHandler}></div>
            <div className="modal__inner" style={{ maxWidth }}>
                <div className="modal__frame">
                    {iframeUrl ? (
                        <>
                            {isLoading && iframeReload ? <Loader size={LoaderSize.LARGE} /> : null}
                            <iframe
                                className={className.content}
                                src={iframeUrl}
                                onLoad={() => {
                                    setIsLoading(Boolean(!iframeReload));
                                }}
                                scrolling="yes"
                                ref={inframeEl}
                            />
                        </>
                    ) : (
                        <div className={className.content}>{children}</div>
                    )}
                </div>
                <div className="modal__control">
                    <div className={className.closeAction} onClick={onCloseClickHandler}>
                        {icon}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;

export { ModalColorScheme };
