import React from 'react';
import { BlockProps } from '../index.types';
import PageTitleImageTextSection, {
    PageTitleImageTextSectionProps,
} from 'components/page-section-two-columns/page-title-image-text-section';
import { decode, decodePossible } from 'lib/decode';

type PageTitleImageTextSectionBlockType = BlockProps & PageTitleImageTextSectionProps;

export default function PageTitleImageTextSectionBlock({
    block,
    ...props
}: PageTitleImageTextSectionBlockType) {
    return (
        <PageTitleImageTextSection
            title={decode(props.title)}
            text={decode(props.text)}
            extraText={decodePossible(props.extraText)}
            imageSrc={props.imageSrc}
            imagePosition={props.imagePosition || 'left'}
            brand={props.brand || 'bumble'}
        />
    );
}
