import React from 'react';
import { BlockProps } from '../index.types';
import PageTextImageSection, {
    PageTextImageSectionProps,
} from 'components/page-section-two-columns/page-text-image-section';
import { decode, decodePossible } from 'lib/decode';

export default function PageTextImageSectionBlock({
    block,
    ...props
}: BlockProps & PageTextImageSectionProps) {
    return (
        <PageTextImageSection
            isSecondary={props.isSecondary}
            title={decode(props.title)}
            text={decode(props.text)}
            extraText={decodePossible(props.extraText)}
            imageSrc={props.imageSrc}
            imageAspectRatio={props.imageAspectRatio}
            imagePosition={props.imagePosition || 'left'}
            ctaLink={props.ctaLink}
            ctaText={decodePossible(props.ctaText)}
            openInNewWindow={props.openInNewWindow}
        />
    );
}
