import * as React from 'react';
import cx from 'classnames';
import SwiperCore, { Pagination, Virtual, Navigation } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import Icon from 'components/icon/icon';

SwiperCore.use([Pagination, Virtual, Navigation]);

export enum ArrowsLayout {
    Relative = 'arrows-relative',
    Absolute = 'arrows-absolute',
}

type SliderProps = SwiperProps & {
    index?: number;
    arrowColorSecondary?: boolean;
    videoCarousel?: boolean;
    onPrevClickActionHandler?: () => void;
    onNextClickActionHandler?: () => void;
    arrowsLayout?: ArrowsLayout.Absolute | ArrowsLayout.Relative;
    /**
     * React 18 / Swiper 9 compatibility
     */
    children?: React.ReactNode | React.ReactNode[] | Element[];
};

const Slider: React.FC<SliderProps> = (props) => {
    const {
        navigation,
        videoCarousel,
        arrowColorSecondary,
        arrowsLayout = ArrowsLayout.Absolute,
        onPrevClickActionHandler,
        onNextClickActionHandler,
        ...sliderProps
    } = props;

    const [swiper, setSwiper]: [
        SwiperProps | undefined,
        React.Dispatch<React.SetStateAction<SwiperProps | undefined>>
    ] = React.useState();
    const [isPrevArrowHoverable, setPrevArrowHoverable] = React.useState(true);
    const [isNextArrowHoverable, setNextArrowHoverable] = React.useState(true);

    if (!props.children) {
        return null;
    }

    // @ts-ignore
    const onNextClick = swiper
        ? () => {
              if (onNextClickActionHandler) {
                  onNextClickActionHandler();
              }
              // @ts-ignore
              swiper.slideNext();
              setNextArrowHoverable(false);
          }
        : undefined;
    // @ts-ignore
    const onPrevClick = swiper
        ? () => {
              if (onPrevClickActionHandler) {
                  onPrevClickActionHandler();
              }
              // @ts-ignore
              swiper.slidePrev();
              setPrevArrowHoverable(false);
          }
        : undefined;

    const onNextLeave = () => {
        setNextArrowHoverable(true);
    };
    const onPrevLeave = () => {
        setPrevArrowHoverable(true);
    };

    const className = {
        arrowLeft: cx({
            swiper__arrow: true,
            'swiper__arrow--left': true,
            'swiper__arrow--secondary': arrowColorSecondary,
            'is-hoverable': isPrevArrowHoverable,
        }),
        arrowRight: cx({
            swiper__arrow: true,
            'swiper__arrow--right': true,
            'swiper__arrow--secondary': arrowColorSecondary,
            'is-hoverable': isNextArrowHoverable,
        }),
        container: cx({
            'swiper-container': true,
            'swiper-container--arrows-within': navigation && !videoCarousel,
            'swiper-container--arrows-for-video-carousel': videoCarousel,
        }),
    };

    const slides = React.Children.toArray(props.children).filter(Boolean);

    let sliderInternalLayout = (
        <>
            <Swiper
                onInit={(swiperInstance) => {
                    if (props.index) {
                        swiperInstance.slideToLoop(props.index, 0);
                    }
                    // @ts-ignore
                    setSwiper(swiperInstance);
                }}
                {...sliderProps}
            >
                {slides.map((slideContent, index) => (
                    <SwiperSlide key={index} virtualIndex={index}>
                        {slideContent}
                    </SwiperSlide>
                ))}
            </Swiper>
            {navigation ? (
                <span
                    slot="container-start"
                    className={className.arrowLeft}
                    onClick={onPrevClick}
                    onMouseLeave={onPrevLeave}
                >
                    <span className="swiper__arrow-icon">
                        <Icon size="stretch" name={Icon.Name.GenericChevronBackStroke} />
                    </span>
                </span>
            ) : null}
            {navigation ? (
                <span
                    slot="container-end"
                    className={className.arrowRight}
                    onClick={onNextClick}
                    onMouseLeave={onNextLeave}
                >
                    <span className="swiper__arrow-icon">
                        <Icon size="stretch" name={Icon.Name.GenericChevronForwardStroke} />
                    </span>
                </span>
            ) : null}
        </>
    );

    if (arrowsLayout === ArrowsLayout.Relative) {
        sliderInternalLayout = (
            <>
                {navigation ? (
                    <span
                        slot="container-start"
                        className={className.arrowLeft}
                        onClick={onPrevClick}
                    >
                        <span className="swiper__arrow-icon">
                            <Icon size="stretch" name={Icon.Name.GenericChevronBackStroke} />
                        </span>
                    </span>
                ) : null}
                <Swiper
                    onInit={(swiperInstance) => {
                        if (props.index) {
                            swiperInstance.slideToLoop(props.index, 0);
                        }
                        // @ts-ignore
                        setSwiper(swiperInstance);
                    }}
                    {...sliderProps}
                >
                    {slides.map((slideContent, index) => (
                        <SwiperSlide key={index} virtualIndex={index}>
                            {slideContent}
                        </SwiperSlide>
                    ))}
                </Swiper>
                {navigation ? (
                    <span
                        slot="container-end"
                        className={className.arrowRight}
                        onClick={onNextClick}
                    >
                        <span className="swiper__arrow-icon">
                            <Icon size="stretch" name={Icon.Name.GenericChevronForwardStroke} />
                        </span>
                    </span>
                ) : null}
            </>
        );
    }

    return <div className={className.container}>{sliderInternalLayout}</div>;
};

export default Slider;
