import React from 'react';
import { BlockProps } from '../index.types';
import PageAboutAppSection, {
    PageAboutAppSectionProps,
} from 'components/page-about-app-section/page-about-app-section';
import { decode, decodePossible } from 'lib/decode';

type PageAboutAppSectionType = BlockProps & PageAboutAppSectionProps;

export default function PageAboutAppSectionBlock({ block, ...props }: PageAboutAppSectionType) {
    return (
        <PageAboutAppSection
            caption={decodePossible(props.caption)}
            title={decode(props.title)}
            text={decode(props.text)}
            extraText={decodePossible(props.extraText)}
            imageSrc={props.imageSrc}
            imageAspectRatio={props.imageAspectRatio}
            imagePosition={props.imagePosition || 'left'}
            brand={props.brand || 'bumble'}
        />
    );
}
