import Localization from 'lib/localization';

// check designs
export default {
    // @TODO update after PRD update - stage 2
    get lxSeoPageTitle() {
        return 'Bumble - Benefits';
    },

    get lxSeoPageDescription() {
        return 'Check our wide range of perks and benefits in health & wellbeing, finance, learning and development.';
    },

    get lxPageHeader() {
        return Localization.get('bumblesite@team_bumble.page_careers.page.header');
    },
    get lxPageTitle() {
        return Localization.get('bumblesite@team_bumble.page_benefits.page.title');
    },
    get lxPageIntro() {
        return Localization.get('bumblesite@team_bumble.page_benefits.page.intro');
    },
    get lxCardAustinTitle() {
        return Localization.get('bumblesite@team_bumble.page_benefits.card.austin.title');
    },
    get lxCardAustinSubtitle() {
        return Localization.get('bumblesite@team_bumble.page_benefits.card.austin.subtitle');
    },
    get lxCardLondonTitle() {
        return Localization.get('bumblesite@team_bumble.page_benefits.card.london.title');
    },
    get lxCardLondonSubtitle() {
        return Localization.get('bumblesite@team_bumble.page_benefits.card.london.subtitle');
    },
    get lxCardMoscowTitle() {
        return Localization.get('bumblesite@team_bumble.page_benefits.card.moscow.title');
    },
    get lxCardMoscowSubtitle() {
        return Localization.get('bumblesite@team_bumble.page_benefits.card.moscow.subtitle');
    },
    get lxCardBarcelonaTitle() {
        return Localization.get('bumblesite@team_bumble.page_benefits.card.barcelona.title');
    },
    get lxCardBarcelonaSubtitle() {
        return Localization.get('bumblesite@team_bumble.page_benefits.card.barcelona.subtitle');
    },
    get lxCardComingSoon() {
        return Localization.get('bumblesite@team_bumble.page_benefits.card.barcelona.coming_soon');
    },
};
