import React from 'react';
import { BlockProps } from '../index.types';
import PageSectionIntroTwoColumns from 'components/page-section-intro/page-section-intro-two-columns';
import { decodePossible } from 'lib/decode';

type PageSectionIntroTwoColumnsBlockProps = BlockProps & {
    caption?: string;
    title?: string;
    text?: string;
    textExtra?: string;
    introSection: 'true' | 'false';
    vAlignTop: 'true' | 'false';
};

export default function PageSectionIntroTwoColumnsBlock({
    block,
    ...props
}: PageSectionIntroTwoColumnsBlockProps) {
    return (
        <PageSectionIntroTwoColumns
            // @TODO pass it as a parameter later
            // introSection={props.introSection === 'true'}
            introSection={true}
            // @TODO pass it as a parameter later
            // vAlignTop={props.vAlignTop === 'true'}
            leftCaption={decodePossible(props.caption)}
            leftTitle={decodePossible(props.title)}
            rightText={decodePossible(props.text)}
            rightExtraText={decodePossible(props.textExtra)}
        />
    );
}
