import * as React from 'react';
import cx from 'classnames';

type SlideProps = {
    children?: React.ReactNode;
    extraClass?: string;
};

const Slide: React.FC<SlideProps> = ({ children, extraClass }) => {
    if (!children) {
        return null;
    }

    const className = cx({
        slide: true,
        [extraClass as string]: extraClass,
    });

    return <div className={className}>{children}</div>;
};

export default Slide;
