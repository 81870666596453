import React from 'react';
import { BlockProps } from '../index.types';
import InterviewSection, {
    PageSectionCeoMessageProps,
} from 'components/page-section-ceo-message/page-section-ceo-message-dynamic';
import { decode, decodePossible } from 'lib/decode';

type InterviewSectionType = BlockProps & PageSectionCeoMessageProps;

export default function InterviewSectionBlock({ block, ...props }: InterviewSectionType) {
    return (
        <InterviewSection
            title={decodePossible(props.title)}
            name={decode(props.name)}
            text={decode(props.text)}
            imageSrc={props.imageSrc}
            imageMobileSrc={props.imageMobileSrc}
        />
    );
}
