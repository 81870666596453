import React from 'react';
import { BlockProps } from '../index.types';
import BenefitSection from 'components/benefit-section/benefit-section';
import { decode, decodePossible } from 'lib/decode';

const INIT_BENEFIT_ITEM = {
    image: '',
    title: '',
    text: '',
};

/**
 * populate 'team-bumble-ui-blocks/benefits-item' in list
 * @TODO: sanitize content and send it as string
 * for all component with { decodeEntities }
 * from "@wordpress/html-entities";
 */
type BenefitListItem = {
    image: string;
    title: string;
    titleHtml?: string;
    text: string;
    textHtml?: string;
    marginLeft?: number;
};

type BenefitList = BenefitListItem[];

function setBenefitList(data: BlockProps['block']['innerBlocks']) {
    const list: BenefitList = [];

    data?.forEach((innerBlock) => {
        const listItem: BenefitListItem = Object.create(INIT_BENEFIT_ITEM);

        const image = innerBlock?.attributes?.filter(
            (attribute) => attribute?.name === 'mediaURL'
        )[0]?.value;

        if (image) {
            listItem.image = image;
        }

        const title = innerBlock?.attributes?.filter((attribute) => attribute?.name === 'title')[0]
            ?.value;

        if (title) {
            listItem.titleHtml = decode(title);
        }

        const subTitle = innerBlock?.attributes?.filter(
            (attribute) => attribute?.name === 'subTitle'
        )[0]?.value;

        if (subTitle) {
            listItem.textHtml = decode(subTitle);
        }

        const marginLeft = innerBlock?.attributes?.filter(
            (attribute) => attribute?.name === 'iconOpticalAlignment'
        )[0]?.value;

        if (marginLeft) {
            listItem.marginLeft = marginLeft ? parseInt(marginLeft, 10) : undefined;
        }

        list.push(listItem);
    });

    return list;
}

type BenefitSectionType = {
    block: BlockProps['block'];
    title?: string;
    column?: 'one' | 'three';
    isSecondary?: 'true' | 'false';
};

export default function BenefitSectionBlock({ block, ...props }: BenefitSectionType) {
    const isSecondary = props.isSecondary === 'true';
    const list = setBenefitList(block.innerBlocks);

    return block.innerBlocks ? (
        <BenefitSection title={decodePossible(props.title)} list={list} isPrimary={!isSecondary} />
    ) : null;
}
